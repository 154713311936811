import { gql } from '@apollo/client';

export const GET_MY_TASKS = gql`
  query GetMyTasks($userId: ID, $teamId: ID) {
    getMyTasks(userId: $userId, teamId: $teamId) {
      _id
      name
      description
      status
      priority
      deadline
      owner
      hours
      hoursEstimated
      timestamp
      subTask {
        _id
        name
        description
        status
        priority
        deadline
        owner
        hours
        hoursEstimated
        timestamp
      }
    }
  }
`;
