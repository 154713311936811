/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useSubscription } from '@apollo/client';
import { Insights } from '@mui/icons-material';
import { Box, Pagination, Paper, Tooltip, Typography } from '@mui/material';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import { GET_PROJECTS_PROGRESS } from 'graphql/queries/Projects/getProjectsProgress';
import { TEAM_RECENT_ACTIVITY } from 'graphql/subscriptions/TeamRecentActivity';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';

export const ProjectsProgress: React.FC = () => {
  const [page, setPage] = useState<number>(1);

  const { currentUser, currentTeam } = useGlobalContext();

  const { data, refetch } = useQuery(GET_PROJECTS_PROGRESS, {
    variables: {
      userId: currentUser?.id,
      teamId: currentTeam?.id,
    },
  });

  const { data: activityData } = useSubscription(TEAM_RECENT_ACTIVITY, {
    variables: { teamId: currentTeam?.id, limit: 10 },
  });

  useEffect(() => {
    refetch();
  }, [activityData]);

  const getStatus = (status: string | null) =>
    currentTeam?.settings?.statuses?.find((x) => x.name === status);

  return (
    <Widget
      width={4}
      height='medium'
      title='Progresjon'
      subtitle='Status for pågående prosjekter'
      icon={<Insights />}
    >
      <Box
        flex={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Box
          display={'flex'}
          flexDirection='column'
          flex={1}
          justifyContent='flex-start'
        >
          {data?.getProjectsProgress?.length > 0 ? (
            data?.getProjectsProgress
              ?.slice((page - 1) * 3, (page - 1) * 3 + 3)
              ?.map((project: any) => {
                return (
                  <Box sx={{ width: '100%', my: 1 }}>
                    <Typography variant='h1' sx={{ fontSize: 15, mb: 1 }}>
                      {project?.name}
                    </Typography>
                    <Paper
                      sx={{
                        width: '100%',
                        borderRadius: 5,
                        height: 30,
                        backgroundColor: '#818589',
                        display: 'flex',
                        overflow: 'hidden',
                      }}
                    >
                      {project?.statusCount?.map((status: any, i: number) => {
                        return (
                          <Tooltip title={status?.status ?? 'To Do'}>
                            <Box
                              sx={{
                                width: `${status?.percentage ?? 0}%`,
                                backgroundColor:
                                  getStatus(status?.status)?.color ?? '#818589',
                                color:
                                  getStatus(status?.status)?.textColor ??
                                  'white',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 12,
                              }}
                            >
                              {status?.percentage}%
                            </Box>
                          </Tooltip>
                        );
                      })}
                    </Paper>
                  </Box>
                );
              })
          ) : (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' sx={{ color: 'GrayText' }}>
                Foreløpig ingen prosjekter
              </Typography>
            </Box>
          )}
        </Box>
        {data?.getProjectsProgress?.length > 3 && (
          <Pagination
            sx={{ alignSelf: 'center' }}
            count={Math.ceil(data?.getProjectsProgress?.length / 5)}
            onChange={(e, val) => setPage(val)}
          />
        )}
      </Box>
    </Widget>
  );
};
