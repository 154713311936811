import { gql } from '@apollo/client';

export const CREATE_TASK = gql`
  mutation CreateTask($projectId: ID, $data: TaskInput) {
    createTask(projectId: $projectId, data: $data) {
      _id
      name
      description
      status
      priority
      deadline
      owner
      hours
      hoursEstimated
      timestamp
    }
  }
`;
