import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { VERIFY_RESET_PASSWORD_REQUEST } from 'graphql/queries';
import { routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotificationContext } from 'state/context/NoticifationContext';
import {
  Typography,
  TextField,
  Grid,
  FormLabel,
  Button,
  CircularProgress,
} from '@mui/material';
import { useRegisterFormValidation } from 'pages/LoginRegister/useRegisterFormValidation';
import Logo from '../../assets/images/logo-colored.png';
import { RESET_PASSWORD } from 'graphql/mutations/User/resetPassword';

export const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [failedAttempt, setFailedAttempt] = useState<boolean>(false);

  const { passwordError, isValid } = useRegisterFormValidation(
    null,
    password,
    confirmPassword
  );

  const location = useLocation();
  const navigate = useNavigate();

  const notifier = useNotificationContext();

  const handleResetError = (error: ApolloError) => {
    let message = 'Noe gikk galt';
    switch (error.message) {
      case 'User not found':
      case 'Invalid request': {
        message = 'Ugyldig lenke';
        break;
      }
      case 'Unable to verify token': {
        message = 'Kunne ikke verifisere forespørselen';
        break;
      }
      case 'Token is invalid': {
        message = 'Forespørselen er ugyldig';
        break;
      }
      case 'Request is expired': {
        message = 'Lenken er utløpt';
        break;
      }
      default: {
        message = 'Noe gikk galt';
        break;
      }
    }
    notifier.addAlert(message, {
      type: 'error',
      message: message,
      onClose: () => notifier.hideAlert(message),
    });
  };

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [verifyRequest] = useLazyQuery(VERIFY_RESET_PASSWORD_REQUEST, {
    onError(err) {
      handleResetError(err);
      navigate(routes.home);
    },
  });

  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('userId');
  const token = searchParams.get('token');

  useEffect(() => {
    if (userId && token) {
      verifyRequest({
        variables: {
          token: token,
          userId: userId,
        },
      });
    } else {
      navigate(routes.home);
    }
  }, [navigate, token, userId, verifyRequest]);

  const handleResetPassword = () => {
    if (isValid) {
      resetPassword({
        variables: {
          password: password,
          userId: userId,
          token: token,
        },
      })
        .then(() => {
          notifier.addAlert(token ?? '', {
            type: 'success',
            message:
              'Passordet ditt er tilbakestilt og du kan logge inn med ditt nye passord',
            onClose: () => notifier.hideAlert(token ?? ''),
            autoHideDuration: 5000,
          });
          navigate(routes.login);
        })
        .catch((err) => {
          handleResetError(err);
        });
    } else {
      setFailedAttempt(true);
    }
  };

  return (
    <div className='reset_password_container'>
      <img src={Logo} alt='statz' className='logo' />
      <Grid
        container
        justifyContent='center'
        spacing={2}
        className='reset_password_card'
      >
        <Grid item xs={12}>
          <Typography
            variant='body1'
            style={{ color: '#fff', textAlign: 'center' }}
          >
            Opprett et nytt passord:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormLabel>Nytt passord</FormLabel>
          <TextField
            fullWidth
            error={failedAttempt && passwordError ? true : false}
            helperText={passwordError && failedAttempt ? passwordError : null}
            placeholder='Passord'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#ebe784',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <FormLabel>Bekreft passord</FormLabel>
          <TextField
            fullWidth
            error={passwordError && failedAttempt ? true : false}
            placeholder='Bekreft passord'
            type='password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#ebe784',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Button
            variant='contained'
            fullWidth
            onClick={handleResetPassword}
            endIcon={loading && <CircularProgress size='sm' />}
          >
            Lagre
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
