import { Edit } from '@mui/icons-material';
import { Box, Avatar, Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, MouseEvent } from 'react';
import { useProjectContext } from 'state/context/ProjectContext';
import { displayName } from 'utils/Stringparser';

interface OwnerCellProps {
  owner: string | null | undefined;
  onClick: (event: MouseEvent<HTMLTableCellElement>) => void;
}

export const OwnerCell: React.FC<OwnerCellProps> = ({ owner, onClick }) => {
  const [inFocus, setInFocus] = useState<boolean>(false);

  const { projectMembers } = useProjectContext();
  const ownerData = projectMembers?.find((data) => data?.id === owner);

  const classes = useOwnerStyles();

  return (
    <Box
      sx={{
        /*  padding: 0, */
        '&:hover': { cursor: 'pointer' },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
      }}
      onMouseOver={() => setInFocus(true)}
      onMouseOut={() => setInFocus(false)}
      onClick={onClick}
    >
      {inFocus && (
        <IconButton
          color='primary'
          sx={{ position: 'absolute', top: 2, left: 2 }}
          disabled
          size='small'
        >
          <Edit className={classes.editIcon} fontSize='small' />
        </IconButton>
      )}
      {ownerData && (
        <Tooltip title={displayName(ownerData)}>
          <Box
            width='100%'
            height='100%'
            display='flex'
            justifyContent={'center'}
            alignItems='center'
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: ownerData?.color ?? undefined,
              }}
              src={ownerData ? ownerData?.avatar ?? undefined : undefined}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

const useOwnerStyles = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.primary.main,
  },
}));
