import { gql } from '@apollo/client';

export const TEAM = gql`
  query Team($id: ID) {
    team(id: $id) {
      id
      name
      settings {
        priorityLabels {
          name
          color
          textColor
        }
        statuses {
          name
          color
          textColor
        }
      }
      members
    }
  }
`;
