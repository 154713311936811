import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { LoginRegister, DashboardPage } from '.';
import { useGlobalContext } from '../state/context/GlobalContext';
/* import { Home } from './Home/Home'; */
import { routes } from './routes';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { Container } from './Container';
import { Profile } from './Profile/Profile';
import { ProjectsPage } from './Projects/ProjectsPage';
import { CreateProject } from './CreateProject/CreateProject';
import { DashboardContextProvider } from 'state/context/DashboardContext';
import { Project } from './ProjectPage/Project';
import { ProjectContextProvider } from 'state/context/ProjectContext';

export const Router: React.FC = () => {
  const { loggedIn } = useGlobalContext();

  return (
    <Routes>
      <Route path={routes.signup} element={<LoginRegister />} />
      <Route path={routes.login} element={<LoginRegister />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      {loggedIn && (
        <React.Fragment>
          {/* {currentUser?.admin && (
            <Route
              path={routes.admin}
              element={
                <AdminContextProvider>
                  <Admin />
                </AdminContextProvider>
              }
            />
          )} */}
          <Route
            path={routes.dashboard}
            element={
              <Container>
                <Outlet />
              </Container>
            }
          >
            <Route
              path={`${routes.dashboard}/`}
              element={
                <DashboardContextProvider>
                  <DashboardPage />
                </DashboardContextProvider>
              }
            />
            <Route path={routes.projects} element={<Outlet />}>
              <Route path={`${routes.projects}/`} element={<ProjectsPage />} />
              <Route path={routes.createProject} element={<CreateProject />} />

              <Route
                path={`${routes.projects}/:id`}
                element={
                  <ProjectContextProvider>
                    <Project />
                  </ProjectContextProvider>
                }
              />
            </Route>
            <Route path={routes.profile} element={<Profile />} />
            <Route path={routes.settings} element={<Profile />} />
          </Route>
        </React.Fragment>
      )}
      <Route
        path='*'
        element={<Navigate to={loggedIn ? routes.dashboard : routes.login} />}
      />
    </Routes>
  );
};
