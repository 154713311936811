import { createContext, useContext } from "react";

/**
 * Lager en context av en gitt type hvor man ikke trenger initState
 */
export const createGenericContext = <T extends unknown>() => {
  const context = createContext<T | undefined>(undefined);
  const useGenericContext = () => {
    const definedContext = useContext(context);
    if (definedContext === undefined) {
      throw new Error("useGenericContext must be used within a provider");
    }
    return definedContext;
  };

  return [useGenericContext, context.Provider] as const;
};
