import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import Logo from 'assets/images/monday-logo.png';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from '@apollo/client';
import { LOGIN_MONDAY } from 'graphql/mutations/Monday/loginMonday';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useGlobalContext } from 'state/context/GlobalContext';
import { AUTHENTICATE_MONDAY } from 'graphql/queries/Monday/authenticateMonday';
import { Todos } from './Todos';

export const Monday: React.FC = () => {
  const classes = useMondayStyles();
  const { currentUser, currentSettings } = useGlobalContext();

  const [token, setToken] = useState<string>('');
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loginMonday] = useMutation(LOGIN_MONDAY);
  const { refetch } = useQuery(AUTHENTICATE_MONDAY, {
    variables: {
      userId: currentUser?.id,
    },
    onCompleted(data) {
      setAuthenticated(data?.authenticateMonday);
      setLoading(false);
      setAttemptingLogin(false);
    },
  });

  const notifier = useNotificationContext();

  const handleLogin = () => {
    setAttemptingLogin(true);
    loginMonday({
      variables: {
        userId: currentUser?.id,
        token: token,
      },
    })
      .then((res) => {
        let success = res?.data?.loginMonday;
        if (success) {
          notifier.addAlert('monday_success', {
            type: 'success',
            message: 'Autentisering velykket',
            onClose: () => notifier.hideAlert('monday_success'),
          });
        } else {
          notifier.addAlert('monday_fail', {
            type: 'error',
            title: 'Noe gikk galt',
            message: 'API-nøkkelen er ikke gyldig',
            onClose: () => notifier.hideAlert('monday_fail'),
          });
        }
      })
      .catch((err) => {
        notifier.addAlert('monday_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: err?.message,
          onClose: () => notifier.hideAlert('monday_fail'),
        });
        setAttemptingLogin(false);
      });
  };

  // 1. Check if logged in (token is present in settings)
  // 2. Check if token is valid
  // 3. If valid -> fetch monday data, else -> Present option to login
  // 4. When data is fetched, display todos

  useEffect(() => {
    refetch().then((res) => {
      /* console.log(res);
      setAuthenticated(res?.data?.authenticateMonday);
      setLoading(false); */
    });
  }, [currentUser?.id, currentSettings?.dashboard?.monday, refetch]);
  return (
    <Widget width={6} height={'xTall'}>
      <img src={Logo} alt='monday' className={classes.logoContainer} />
      {loading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : !authenticated ? (
        <>
          <Box className={classes.instructions}>
            <Typography variant='h6'>Få tilgang til Monday</Typography>
            <Typography>
              1. Logg inn med din bruker på{' '}
              <a href='https://monday.com'>Monday.com</a>
            </Typography>
            <Typography>
              2. Klikk på din avatar nede i venstre hjørne
            </Typography>
            <Typography>3. Velg "Developers" fra menyen</Typography>
            <Typography>4. Klikk på nedtrekksmenyen "Developer"</Typography>
            <Typography>5. Velg deretter "My Access Tokens"</Typography>
            <Typography>6. Kopier din API-nøkkel</Typography>
          </Box>
          <Box className={classes.form}>
            <TextField
              variant='outlined'
              placeholder='API-nøkkel...'
              onChange={(e) => setToken(e.target.value)}
            />
            {!attemptingLogin ? (
              <Button onClick={handleLogin}>Logg inn</Button>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </>
      ) : (
        <Todos />
      )}
    </Widget>
  );
};

const useMondayStyles = makeStyles((theme) => ({
  logoContainer: {
    width: '50%',
    objectFit: 'contain',
    marginBottom: 20,
  },
  instructions: {
    marginBottom: 20,
  },
  form: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
