import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut as ReactDoughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';
//const _ = require('lodash');

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutOptions = {
  values: number[];
  colors?: string[];
  margin?: string | number;
  height?: string | number;
  width?: string | number;
  labels?: string[];
  target?: number | null | undefined;
  title?: string | null | undefined;
  subtitle?: string | null | undefined;
};

export const Doughnut: React.FC<DoughnutOptions> = ({
  margin,
  height,
  width,
  labels,
  target,
  title,
  subtitle,
  colors,
  values,
}) => {
  //const sum = _.sum(values);

  return (
    <Box
      sx={{
        width: '100%',
        flex: 1,
      }}
    >
      <ReactDoughnut
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: title ? true : false,
              text: title ?? '',
              font: { size: 20 },
            },
            subtitle: {
              display: subtitle ? true : false,
              text: subtitle ?? '',
            },
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: colors,
              borderWidth: 1,
              borderColor: colors,
            },
          ],
        }}
      />
      {/* <Typography className='centered-text'>
        {values ? `${NumberFormatter.format(sum)}` : ''}
        {target && (
          <Typography className='total'>
            {`/ ${NumberFormatter.format(target ?? 0)}`}
          </Typography>
        )}
      </Typography> */}
    </Box>
  );
};
