import { gql } from '@apollo/client';

export const GET_RECENT_ACTIVITY = gql`
  query GetRecentActivity(
    $teamId: ID
    $projectId: ID
    $userId: ID
    $limit: Int
  ) {
    getRecentActivity(
      teamId: $teamId
      projectId: $projectId
      userId: $userId
      limit: $limit
    ) {
      type
      madeBy
      timestamp
      description
    }
  }
`;
