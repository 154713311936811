import { Card, CardHeader, Grid, GridSize, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FancyIcon } from 'components/FancyIcon/FancyIcon';
import React from 'react';

const HEIGHTS = {
  xShort: 120,
  short: 240,
  medium: 360,
  tall: 480,
  xTall: 600,
};

interface WidgetProps {
  width?: GridSize;
  height?: keyof typeof HEIGHTS;
  title?: string;
  subtitle?: string;
  icon?: React.ReactElement;
}

export const Widget: React.FC<WidgetProps> = ({
  width,
  height,
  title,
  subtitle,
  children,
  icon,
}) => {
  const classes = useWidgetStyles();
  return (
    <Grid item xs={12} md={width ?? 4} className={classes.root}>
      <Card
        sx={{ height: HEIGHTS[height ?? 'medium'] }}
        className={classes.card}
      >
        <CardHeader
          avatar={<FancyIcon icon={icon} />}
          title={<Typography className={classes.title}>{title}</Typography>}
          subheader={subtitle}
        />
        {children}
      </Card>
    </Grid>
  );
};

const useWidgetStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  card: {
    width: '100%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));
