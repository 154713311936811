import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createGenericContext } from './createGenericContext';
import { Settings, User } from 'types/user';
import { AUTHENTICATE } from 'graphql/queries';
import {
  ApolloError,
  useLazyQuery,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { Team } from 'types/team';
import { TEAM } from 'graphql/queries/Team/Team';
import { client } from 'graphql/config';
import { SETTINGS_SUBSCRIPTION } from 'graphql/subscriptions/SettingsSubscription';
import { TEAM_MEMBERS } from 'graphql/queries/Team/TeamMembers';
import { GET_ALL_PROJECTS } from 'graphql/queries/Projects/getAllProjects';
import { PROJECTS_SUBSCRIPTION } from 'graphql/subscriptions/ProjectsSubscription';

interface GlobalContextType {
  loggedIn: boolean;
  loading: boolean;
  currentUser: User | null | undefined;
  currentTeam: Team | null | undefined;
  currentSettings: Settings | null | undefined;
  allProjects: any[] | null | undefined;
  projectsLoading: boolean;
  teamMembers: any[];
  clearAuth: () => void;
  setUser: (user: User | null | undefined) => void;
  setTeam: (team: Team | null | undefined) => void;
  setSettings: (settings: Settings | null | undefined) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setLoading: (loading: boolean) => void;
  refreshProjects: () => void;
  displayName: string | undefined;
}

export const [useGlobalContext, BareGlobalContextProvider] =
  createGenericContext<GlobalContextType>();

export const GlobalContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>();
  const [team, setTeam] = useState<Team | null>();
  const [settings, setSettings] = useState<Settings | null | undefined>();
  const [fetchTeam] = useLazyQuery(TEAM);

  useSubscription(SETTINGS_SUBSCRIPTION, {
    variables: { userId: user?.id },
    onSubscriptionData(data) {
      console.log(data);
      setSettings(data?.subscriptionData?.data?.settings?.data);
    },
  });

  // Reauthenticate user on every render
  useQuery(AUTHENTICATE, {
    onCompleted(data) {
      const { authenticateUser: userData } = data;
      if (userData) {
        setUser(userData);
        setSettings(userData?.settings);
      } else {
        handleClearAuth();
        setLoading(false);
      }
    },
    onError(error) {
      setLoggedIn(false);
      setUser(null);
      setLoading(false);
      console.error(JSON.stringify(error));
    },
  });

  const { data: memberData } = useQuery(TEAM_MEMBERS, {
    variables: {
      teamId: team?.id,
    },
  });

  const {
    data: projectsData,
    loading: projectsLoading,
    subscribeToMore,
    refetch,
  } = useQuery(GET_ALL_PROJECTS, {
    variables: {
      teamId: team?.id,
      userId: user?.id,
    },
    onError(err) {
      console.error(err);
    },
  });

  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: PROJECTS_SUBSCRIPTION,
      variables: { teamId: team?.id, userId: user?.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data?.getAllProjects) return prev;
        else {
          return Object.assign(
            {},
            {
              getAllProjects: [
                ...prev.getAllProjects,
                subscriptionData?.data?.getAllProjects,
              ],
            }
          );
        }
      },
    });

    return () => unsubscribe();
  }, [team?.id, user?.id, subscribeToMore]);

  // On user change, fetch team and log in
  useEffect(() => {
    if (user) {
      fetchTeam({
        variables: {
          id: user?.teams?.[0],
        },
      })
        .then((res: any) => {
          setTeam(res.data.team as Team);
        })
        .catch((err: ApolloError) => {
          console.error(JSON.stringify(err, null, 2));
        });
    }
  }, [fetchTeam, user]);

  useEffect(() => {
    if (user && team) {
      setLoggedIn(true);
      setLoading(false);
    }
  }, [team, user, settings]);

  const handleClearAuth = useCallback(() => {
    setLoggedIn(false);
    setUser(null);
    setTeam(null);
    setSettings(null);
    client.clearStore().then(() => {
      console.log('store cleared');
    });
  }, []);

  const handleSetUser = useCallback((user: User | null | undefined) => {
    setUser(user);
  }, []);

  const handleSetTeam = useCallback((team: Team | null | undefined) => {
    setTeam(team);
  }, []);

  const handleSetSettings = useCallback(
    (newSettings: Settings | null | undefined) => {
      setSettings((prevState: any) => {
        return {
          ...prevState,
          ...newSettings,
        };
      });
    },
    []
  );

  const handleSetLoggedIn = useCallback((loggedIn: boolean) => {
    setLoggedIn(loggedIn);
  }, []);

  const handleSetLoading = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  const displayName = useCallback(() => {
    if (user?.username) {
      return user?.username;
    } else if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    } else {
      return user?.email;
    }
  }, [user?.email, user?.firstName, user?.lastName, user?.username]);

  const value: GlobalContextType = useMemo(
    () => ({
      loggedIn,
      loading: loading,
      currentUser: user,
      currentTeam: team,
      currentSettings: settings,
      allProjects: projectsData?.getAllProjects,
      projectsLoading: projectsLoading,
      teamMembers: memberData?.teamMembers ?? [],
      clearAuth: handleClearAuth,
      setUser: handleSetUser,
      setTeam: handleSetTeam,
      setSettings: handleSetSettings,
      setLoggedIn: handleSetLoggedIn,
      setLoading: handleSetLoading,
      refreshProjects: refetch,
      displayName: displayName(),
    }),
    [
      loggedIn,
      loading,
      user,
      team,
      settings,
      projectsData?.getAllProjects,
      projectsLoading,
      memberData?.teamMembers,
      handleClearAuth,
      handleSetUser,
      handleSetTeam,
      handleSetSettings,
      handleSetLoggedIn,
      handleSetLoading,
      refetch,
      displayName,
    ]
  );
  return (
    <BareGlobalContextProvider value={value}>
      {children}
    </BareGlobalContextProvider>
  );
};
