import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Drawer } from 'components/layout/Drawer/Drawer';
import { Notifications } from 'components/Notifications/Notifications';

export const Container: React.FC = ({ children }) => {
  const classes = useContainerStyles();

  return (
    <Box className={classes.root}>
      <Drawer />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Box sx={{ alignSelf: 'flex-end', p: 1 }}>
          <Notifications />
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
};

const useContainerStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: '#eeeeee',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#eeeeee',
    position: 'relative',
  },
}));
