export const useRegisterFormValidation = (
  email?: string | null,
  password?: string | null,
  confirmPassword?: string | null
) => {
  const getEmailError = (email: string) => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return null;
    } else if (email.length === 0) {
      return 'Påkrevd';
    } else {
      return 'Ugyldig format';
    }
  };

  const getPasswordError = (password: string, confirmPassword: string) => {
    if (password.length === 0) {
      return 'Påkrevd';
    } else if (password.length < 8) {
      return 'Passord må være minst 8 tegn';
    } else if (password.length > 15) {
      return 'Passord kan maksimalt være 15 tegn';
    } else if (!/\d/.test(password)) {
      return 'Passord inneholde minst 1 tall';
    } else if (password !== confirmPassword) {
      return 'Passordene må være like';
    } else {
      return null;
    }
  };

  const emailError =
    email !== null && email !== undefined ? getEmailError(email) : undefined;
  const passwordError =
    password !== null &&
    password !== undefined &&
    confirmPassword !== null &&
    confirmPassword !== undefined
      ? getPasswordError(password, confirmPassword)
      : undefined;

  return {
    emailError,
    passwordError,
    isValid: !emailError && !passwordError,
  };
};
