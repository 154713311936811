import { gql } from '@apollo/client';

export const RECENT_ACTIVITY_SUBSCRIPTION = gql`
  subscription RecentActivity(
    $teamId: ID
    $projectId: ID
    $userId: ID
    $limit: Int
  ) {
    recentActivity(
      teamId: $teamId
      projectId: $projectId
      userId: $userId
      limit: $limit
    ) {
      type
      madeBy
      timestamp
      description
    }
  }
`;
