import { gql } from '@apollo/client';

export const DUPLICATE_PROJECT = gql`
  mutation DuplicateProject(
    $projectId: ID
    $name: String
    $includeTasks: Boolean
  ) {
    duplicateProject(
      projectId: $projectId
      name: $name
      includeTasks: $includeTasks
    ) {
      id
    }
  }
`;
