import { gql } from '@apollo/client';

export const SET_TASK_OWNER = gql`
  mutation SetTaskOwner(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $owner: String
  ) {
    setTaskOwner(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      owner: $owner
    ) {
      name
    }
  }
`;
