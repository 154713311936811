import { useMutation } from '@apollo/client';
import {
  Abc,
  Add,
  Alarm,
  Assignment,
  CalendarToday,
  Delete,
  Edit,
  Flag,
  History,
  Person,
  PlusOne,
  PriorityHighRounded,
  RemoveCircle,
  Save,
  Timelapse,
  Timer,
} from '@mui/icons-material';
import {
  DesktopDatePicker,
  LocalizationProvider,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ADD_PROJECT_LEADER } from 'graphql/mutations/Projects/addProjectLeader';
import { ADD_PROJECT_MEMBER } from 'graphql/mutations/Projects/addProjectMember';
import { REMOVE_PROJECT_LEADER } from 'graphql/mutations/Projects/removeProjectLeader';
import { REMOVE_PROJECT_MEMBER } from 'graphql/mutations/Projects/removeProjectMember';
import { SET_PROJECT_DESCRIPTION } from 'graphql/mutations/Projects/setProjectDescription';
import { SET_PROJECT_END_DATE } from 'graphql/mutations/Projects/setProjectEndDate';
import { SET_PROJECT_NAME } from 'graphql/mutations/Projects/setProjectName';
import { SET_PROJECT_START_DATE } from 'graphql/mutations/Projects/setProjectStartDate';
import React, { useRef, useState } from 'react';

import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { displayName } from 'utils/Stringparser';
import {
  renderDateString,
  renderPrettyDateTimeString,
} from 'utils/DateHandler';
import { SET_PROJECT_HOURS_BUDGET } from 'graphql/mutations/Projects/setProjectHoursBudget';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';

export const SIDEBAR_WIDTH = 375;

export const Details: React.FC = () => {
  const classes = useDetailStyles();
  const notifier = useNotificationContext();
  const [editDesc, setEditDesc] = useState<string | null>(null);
  const [editName, setEditName] = useState<string | null>(null);
  const [editStartDate, setEditStartDate] = useState<Date | null>(null);
  const [editEndDate, setEditEndDate] = useState<Date | null>(null);
  const [editHoursBudget, setEditHoursBudget] = useState<
    number | null | undefined
  >();
  const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [popperAnchor, setPopperAnchor] = useState<any>(null);

  const popperRef = useRef(null);

  const { teamMembers, currentTeam, currentUser } = useGlobalContext();
  const { currentProject, projectMembers, admin, recentActivity } =
    useProjectContext();

  const [changeName] = useMutation(SET_PROJECT_NAME);
  const [changeDescription, { loading: descriptionLoading }] = useMutation(
    SET_PROJECT_DESCRIPTION
  );
  const [changeHoursBudget] = useMutation(SET_PROJECT_HOURS_BUDGET);
  const [changeStartDate] = useMutation(SET_PROJECT_START_DATE);
  const [changeEndDate] = useMutation(SET_PROJECT_END_DATE);
  const [addMember] = useMutation(ADD_PROJECT_MEMBER);
  const [removeMember] = useMutation(REMOVE_PROJECT_MEMBER);
  const [addProjectLeader] = useMutation(ADD_PROJECT_LEADER);
  const [removeProjectLeader] = useMutation(REMOVE_PROJECT_LEADER);
  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const handleSaveName = (name: string) => {
    changeName({
      variables: {
        projectId: currentProject?.id,
        name: name,
      },
    })
      .then((res) => {
        setEditName(null);
        log({
          variables: {
            data: {
              type: `project-name`,
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret navn på prosjektet til "${name}"`,
            },
          },
        });
      })
      .catch((err) => {
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke lagre endringer',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        });
      });
  };

  const handleSaveDescription = (description: string) => {
    changeDescription({
      variables: {
        projectId: currentProject?.id,
        description: description,
      },
    })
      .then(() => {
        setEditDesc(null);
        log({
          variables: {
            data: {
              type: `project-description`,
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret prosjektbeskrivelsen`,
            },
          },
        });
      })
      .catch((err) =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke lagre endringer',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const handleSaveHoursBudget = (hours: number | null | undefined) => {
    changeHoursBudget({
      variables: {
        projectId: currentProject?.id,
        hours: hours,
      },
    })
      .then(() => {
        setEditHoursBudget(undefined);
        log({
          variables: {
            data: {
              type: 'project-hoursBudget',
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret prosjektets timebudsjett til ${hours} timer`,
            },
          },
        });
      })
      .catch(() =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke lagre endringer',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const handleSaveStartDate = (startDate: Date | null) => {
    changeStartDate({
      variables: { projectId: currentProject?.id, startDate: startDate },
    })
      .then(() => {
        setEditStartDate(null);
        log({
          variables: {
            data: {
              type: 'project-startDate',
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret prosjektets startdato til ${renderDateString(
                startDate?.toISOString()
              )}`,
            },
          },
        });
      })
      .catch((err) =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke lagre endringer',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const handleSaveEndDate = (endDate: Date | null) => {
    changeEndDate({
      variables: { projectId: currentProject?.id, endDate: endDate },
    })
      .then(() => {
        setEditEndDate(null);
        log({
          variables: {
            data: {
              type: 'project-endDate',
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret prosjektets sluttdato til ${renderDateString(
                endDate?.toISOString()
              )}`,
            },
          },
        });
      })
      .catch((err) =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke lagre endringer',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const handleAddMember = (member: string) => {
    addMember({
      variables: {
        projectId: currentProject?.id,
        member: member,
      },
    })
      .then(() => {
        setAddMemberOpen(false);
        log({
          variables: {
            data: {
              type: 'project-addMember',
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `La til ${displayName(
                teamMembers?.find((x) => x.id === member)
              )} i prosjektet`,
            },
          },
        });
        addNotification({
          variables: {
            data: {
              type: 'project-addMember',
              sender: currentUser?.id,
              receivers: [member],
              message: 'la deg til i et prosjekt',
            },
          },
        });
      })
      .catch(() =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke legge til teammedlem',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const handleRemoveMember = (member: string) => {
    if (currentProject?.projectLeaders?.includes(member)) {
      return notifier.addAlert('project_edit_fail', {
        type: 'error',
        title: 'Kan ikke fjerne prosjektleder',
        message: 'Fjern prosjektlederrollen før du sletter teammedlemmet',
        onClose: () => notifier.hideAlert('project_edited'),
        autoHide: true,
      });
    }
    removeMember({
      variables: {
        projectId: currentProject?.id,
        member: member,
      },
    })
      .then(() => {
        setSelectedMember(null);
        setPopperAnchor(null);
      })
      .catch(() =>
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke fjerne teammedlem',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
        })
      );
  };

  const toggleMember =
    (member: string) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (selectedMember !== member) {
        setSelectedMember(member);
        setPopperAnchor(e.currentTarget);
      } else {
        setSelectedMember(null);
        setPopperAnchor(null);
      }
    };

  const toggleProjectLeader =
    (member: string, currentLeaders: string[]) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!currentLeaders.includes(member)) {
        addProjectLeader({
          variables: {
            projectId: currentProject?.id,
            member: member,
          },
        })
          .then(() => {
            setPopperAnchor(null);
            setSelectedMember(null);
            log({
              variables: {
                data: {
                  type: 'project-projectLeader',
                  madeBy: currentUser?.id,
                  teamId: currentTeam?.id,
                  projectId: currentProject?.id,
                  description: `Gjorde ${displayName(
                    teamMembers?.find((x) => x.id === member)
                  )} til prosjektleder`,
                },
              },
            });
            addNotification({
              variables: {
                data: {
                  type: 'project-projectLeader',
                  sender: currentUser?.id,
                  receivers: [member],
                  message: 'gjorde deg til prosjektleder',
                  subject: currentProject?.name,
                },
              },
            });
          })
          .catch((err) =>
            notifier.addAlert('project_edit_fail', {
              type: 'error',
              title: 'Noe gikk galt',
              message: 'Kunne ikke legge til prosjektleder',
              onClose: () => notifier.hideAlert('project_edited'),
              autoHide: true,
            })
          );
      } else if (currentLeaders?.length > 1) {
        removeProjectLeader({
          variables: {
            projectId: currentProject?.id,
            member: member,
          },
        })
          .then(() => {
            setPopperAnchor(null);
            setSelectedMember(null);
          })
          .catch((err) => {
            notifier.addAlert('project_edit_fail', {
              type: 'error',
              title: 'Noe gikk galt',
              message: 'Kunne ikke fjerne prosjektleder',
              onClose: () => notifier.hideAlert('project_edited'),
              autoHide: true,
            });
          });
      } else {
        notifier.addAlert('project_edit_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Det må være minimum 1 prosjektleder per prosjekt',
          onClose: () => notifier.hideAlert('project_edited'),
          autoHide: true,
          autoHideDuration: 5000,
        });
      }
    };

  const renderLogIcon = (type: string) => {
    let parsedType = type.split(':');
    let category = parsedType?.[0];
    let subCategory = parsedType?.[1];
    switch (category) {
      case 'task-add': {
        return (
          <TimelineDot>
            <PlusOne />
          </TimelineDot>
        );
      }
      case 'task-status': {
        let status = currentTeam?.settings?.statuses?.find(
          (stat) => stat?.name === subCategory
        );
        return (
          <TimelineDot
            sx={{ backgroundColor: status?.color, color: status?.textColor }}
          >
            <Assignment />
          </TimelineDot>
        );
      }
      case 'task-priority': {
        let priority = currentTeam?.settings?.priorityLabels?.find(
          (prio) => prio?.name === subCategory
        );
        return (
          <TimelineDot
            sx={{
              backgroundColor: priority?.color,
              color: priority?.textColor,
            }}
          >
            <PriorityHighRounded />
          </TimelineDot>
        );
      }
      case 'tasks-deleted': {
        return (
          <TimelineDot color='error'>
            <Delete />
          </TimelineDot>
        );
      }
      case 'task-deadline': {
        return (
          <TimelineDot color='info'>
            <CalendarToday />
          </TimelineDot>
        );
      }
      case 'task-hours': {
        return (
          <TimelineDot color='info'>
            <Timelapse />
          </TimelineDot>
        );
      }
      case 'task-hoursEstimated': {
        return (
          <TimelineDot color='info'>
            <Timer />
          </TimelineDot>
        );
      }
      case 'task-name': {
        return (
          <TimelineDot color='info'>
            <Abc />
          </TimelineDot>
        );
      }
      case 'task-owner': {
        return (
          <TimelineDot color='info'>
            <Person />
          </TimelineDot>
        );
      }
      case 'project-name': {
        return (
          <TimelineDot>
            <Abc />
          </TimelineDot>
        );
      }
      case 'project-description': {
        return (
          <TimelineDot>
            <Assignment />
          </TimelineDot>
        );
      }
      case 'project-hoursBudget': {
        return (
          <TimelineDot>
            <Timer />
          </TimelineDot>
        );
      }
      case 'project-addMember': {
        return (
          <TimelineDot>
            <Person />
          </TimelineDot>
        );
      }
      case 'project-projectLeader': {
        return (
          <TimelineDot>
            <Flag />
          </TimelineDot>
        );
      }
      case 'project-startDate': {
        return (
          <TimelineDot>
            <CalendarToday />
          </TimelineDot>
        );
      }
      case 'project-endDate': {
        return (
          <TimelineDot>
            <Alarm />
          </TimelineDot>
        );
      }
      default: {
        return (
          <TimelineDot>
            <Assignment />
          </TimelineDot>
        );
      }
    }
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} position='relative'>
          <Grid item xs={12}>
            <Card className={classes.descriptionCard}>
              <CardContent>
                <Box className={classes.descriptionHeader}>
                  <Typography variant='h6' mr={1}>
                    Projsektbeskrivelse
                  </Typography>
                  {!editDesc && admin && (
                    <IconButton
                      sx={{ color: 'secondary.dark' }}
                      onClick={() => setEditDesc(currentProject?.description)}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Box>
                {!editDesc ? (
                  <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {currentProject?.description}
                  </Typography>
                ) : (
                  <React.Fragment>
                    <TextField
                      value={editDesc}
                      onChange={(e) => setEditDesc(e.target.value)}
                      multiline
                      rows={6}
                      fullWidth
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                    <Box display={'flex'}>
                      <Button color='inherit' onClick={() => setEditDesc(null)}>
                        Avbryt
                      </Button>
                      <Button
                        onClick={() => handleSaveDescription(editDesc)}
                        sx={{ color: 'secondary.dark' }}
                        endIcon={
                          descriptionLoading && <CircularProgress size={20} />
                        }
                      >
                        Lagre
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.projectCard}>
              <CardContent>
                <Typography variant='h6' mb={2}>
                  Detaljer
                </Typography>
                <Grid container justifyContent={'space-between'} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography fontWeight={'bold'} mr={1}>
                      Prosjektnavn:
                    </Typography>
                    {!editName ? (
                      <React.Fragment>
                        <Typography mr={1}>{currentProject?.name}</Typography>
                        {admin && (
                          <IconButton
                            size={'small'}
                            sx={{ color: 'secondary.dark' }}
                            onClick={() =>
                              setEditName(currentProject?.name ?? '')
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ClickAwayListener
                          onClickAway={() => setEditName(null)}
                        >
                          <TextField
                            size='small'
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                          />
                        </ClickAwayListener>

                        <IconButton
                          size={'small'}
                          onClick={() => handleSaveName(editName)}
                          sx={{ color: 'secondary.dark' }}
                        >
                          <Save />
                        </IconButton>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography fontWeight={'bold'} mr={1}>
                      Timebudsjett
                    </Typography>
                    {editHoursBudget === undefined ? (
                      <React.Fragment>
                        <Typography mr={1}>
                          {!currentProject?.hoursBudget
                            ? 'Ikke satt'
                            : currentProject?.hoursBudget}
                        </Typography>
                        {admin && (
                          <IconButton
                            size={'small'}
                            sx={{ color: 'secondary.dark' }}
                            onClick={() =>
                              setEditHoursBudget(
                                currentProject?.hoursBudget ?? 0
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ClickAwayListener
                          onClickAway={() => setEditHoursBudget(undefined)}
                        >
                          <TextField
                            size='small'
                            value={editHoursBudget}
                            type='number'
                            onChange={(e) =>
                              setEditHoursBudget(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                          />
                        </ClickAwayListener>

                        <IconButton
                          size={'small'}
                          onClick={() => handleSaveHoursBudget(editHoursBudget)}
                          sx={{ color: 'secondary.dark' }}
                        >
                          <Save />
                        </IconButton>
                      </React.Fragment>
                    )}
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid
                      item
                      md={12}
                      lg={6}
                      xl={6}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography fontWeight={'bold'} mr={1}>
                        Startdato:
                      </Typography>
                      {!editStartDate ? (
                        <React.Fragment>
                          <Typography mr={1}>
                            {currentProject?.startDate
                              ? new Date(
                                  currentProject?.startDate
                                ).toLocaleDateString()
                              : 'Ikke satt'}
                          </Typography>
                          {admin && (
                            <IconButton
                              size={'small'}
                              sx={{ color: 'secondary.dark' }}
                              onClick={() =>
                                setEditStartDate(
                                  currentProject?.startDate
                                    ? new Date(currentProject?.startDate)
                                    : new Date()
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ClickAwayListener
                            onClickAway={() => setEditStartDate(null)}
                          >
                            <DesktopDatePicker
                              inputFormat='dd/MM/yyyy'
                              value={editStartDate}
                              onChange={(date) => handleSaveStartDate(date)}
                              renderInput={(params) => (
                                <TextField size='small' {...params} />
                              )}
                            />
                          </ClickAwayListener>
                          <Tooltip title='Fjern startdato'>
                            <IconButton
                              color='error'
                              size='small'
                              onClick={() => handleSaveStartDate(null)}
                            >
                              <RemoveCircle />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={12}
                      lg={6}
                      xl={6}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography fontWeight={'bold'} mr={1}>
                        Sluttdato:
                      </Typography>
                      {!editEndDate ? (
                        <React.Fragment>
                          <Typography mr={1}>
                            {currentProject?.endDate
                              ? new Date(
                                  currentProject?.endDate
                                ).toLocaleDateString()
                              : 'Ikke satt'}
                          </Typography>
                          {admin && (
                            <IconButton
                              size={'small'}
                              sx={{ color: 'secondary.dark' }}
                              onClick={() =>
                                setEditEndDate(
                                  currentProject?.endDate
                                    ? new Date(currentProject?.endDate)
                                    : new Date()
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ClickAwayListener
                            onClickAway={() => setEditEndDate(null)}
                          >
                            <DesktopDatePicker
                              inputFormat='dd/MM/yyyy'
                              value={editEndDate}
                              onChange={(date) => handleSaveEndDate(date)}
                              renderInput={(params) => (
                                <TextField size='small' {...params} />
                              )}
                            />
                          </ClickAwayListener>
                          <Tooltip title='Fjern sluttdato'>
                            <IconButton
                              color='error'
                              size='small'
                              onClick={() => handleSaveEndDate(null)}
                            >
                              <RemoveCircle />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      )}
                    </Grid>
                  </LocalizationProvider>

                  <Grid item xs={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.membersCard}>
              <CardContent>
                <Typography variant='h6' mb={2}>
                  Team
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={3} className={classes.avatarContainer}>
                    <Popper
                      open={addMemberOpen}
                      placement='bottom'
                      anchorEl={popperRef?.current}
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      <ClickAwayListener
                        onClickAway={() => setAddMemberOpen(false)}
                      >
                        <Paper
                          sx={{
                            position: 'relative',
                            mt: '10px',

                            '&::before': {
                              backgroundColor: 'white',
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              width: 12,
                              height: 12,
                              top: -6,
                              transform: 'rotate(45deg)',
                              left: 'calc(50% - 6px)',
                              borderColor: '#ced4da',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderWidth: 0.1,
                            },
                          }}
                        >
                          <List
                            sx={{
                              maxWidth: 300,
                              maxHeight: 250,
                              overflow: 'auto',
                              px: 2,
                            }}
                            subheader={
                              <ListSubheader component='div'>
                                Teammedlemmer
                              </ListSubheader>
                            }
                          >
                            {teamMembers
                              ?.filter(
                                (member) =>
                                  !currentProject?.members?.includes(member?.id)
                              )
                              ?.map((member) => (
                                <ListItemButton
                                  onClick={() => handleAddMember(member?.id)}
                                >
                                  <ListItemIcon>
                                    <Avatar
                                      sx={{
                                        width: 24,
                                        height: 24,
                                        bgcolor: member?.color ?? undefined,
                                      }}
                                      src={member?.avatar ?? undefined}
                                    />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {displayName(member)}
                                  </ListItemText>
                                </ListItemButton>
                              ))}
                          </List>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                    <IconButton
                      ref={popperRef}
                      onClick={() => setAddMemberOpen(true)}
                      disabled={!admin}
                    >
                      <Avatar className={classes.addMember}>
                        <Add sx={{ color: 'secondary.dark' }} />
                      </Avatar>
                    </IconButton>
                    <Typography
                      className={classes.nameLabel}
                      mx={1}
                      color={'primary'}
                    >
                      Legg til medlem
                    </Typography>
                  </Grid>
                  <Popper
                    open={selectedMember && popperAnchor ? true : false}
                    anchorEl={popperAnchor}
                    placement='top'
                    transition
                    style={{ zIndex: 2 }}
                  >
                    {({ TransitionProps }) => (
                      <ClickAwayListener
                        onClickAway={() => {
                          setSelectedMember(null);
                          setPopperAnchor(null);
                        }}
                      >
                        <Fade timeout={250} {...TransitionProps}>
                          <Box display='flex' flexDirection='column'>
                            {selectedMember && popperAnchor && (
                              <React.Fragment>
                                <Tooltip
                                  title={
                                    currentProject?.projectLeaders?.includes(
                                      selectedMember
                                    )
                                      ? 'Fjern som prosjektleder'
                                      : 'Gjør til prosjektleder'
                                  }
                                >
                                  <IconButton
                                    sx={{
                                      boxShadow: 3,
                                      mb: 2,
                                      backgroundColor: 'white',
                                    }}
                                    size='small'
                                    color={
                                      !currentProject?.projectLeaders?.includes(
                                        selectedMember
                                      )
                                        ? 'default'
                                        : 'info'
                                    }
                                    onClick={toggleProjectLeader(
                                      selectedMember ?? '',
                                      currentProject?.projectLeaders ?? []
                                    )}
                                  >
                                    <Flag />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title='Fjern prosjektmedlem'>
                                  <IconButton
                                    sx={{
                                      boxShadow: 3,
                                      mb: 2,
                                      backgroundColor: 'white',
                                      '&:hover': {
                                        opacity: 0.9,
                                        backgroundColor: 'white',
                                      },
                                    }}
                                    size='small'
                                    color='error'
                                    onClick={() =>
                                      handleRemoveMember(selectedMember ?? '')
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </React.Fragment>
                            )}
                          </Box>
                        </Fade>
                      </ClickAwayListener>
                    )}
                  </Popper>
                  {projectMembers?.map((member: any) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        md={3}
                        sx={{ position: 'relative' }}
                        className={classes.avatarContainer}
                      >
                        <IconButton
                          sx={{ position: 'relative' }}
                          onClick={toggleMember(member?.id)}
                          disabled={!admin}
                        >
                          <Badge
                            overlap='circular'
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            badgeContent={
                              currentProject?.projectLeaders?.includes(
                                member?.id
                              ) && (
                                <Tooltip title='Prosjektleder'>
                                  <Flag color='info' />
                                </Tooltip>
                              )
                            }
                          >
                            <Avatar src={member?.avatar} />
                          </Badge>
                        </IconButton>
                        <Typography className={classes.nameLabel} mx={1}>
                          {displayName(member)}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Box
            sx={{
              width: SIDEBAR_WIDTH,
              position: 'absolute',
              paddingTop: 2,
              right: -SIDEBAR_WIDTH - 20,
              height: '100%',
            }}
          >
            <Card sx={{ width: '100%', height: '100%' }}>
              <CardContent>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='h6' mb={2}>
                    Nylig aktivitet
                  </Typography>
                  <History color='primary' sx={{ fontSize: 30 }} />
                </Box>
                <Timeline position='right'>
                  {recentActivity?.map((log) => {
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0', flex: 0.4 }}
                          align='right'
                          variant='body2'
                          color='text.secondary'
                        >
                          {renderPrettyDateTimeString(log?.timestamp)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          {renderLogIcon(log?.type)}
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography
                            variant='h6'
                            sx={{ fontSize: 14 }}
                            component='span'
                          >
                            {displayName(
                              projectMembers?.find(
                                (memb) => log?.madeBy === memb?.id
                              )
                            )}
                          </Typography>
                          <Typography variant='body2' color='text.secondary'>
                            {log?.description}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

const useDetailStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    maxWidth: 1000,
  },
  leftContainer: {
    padding: 20,
  },
  rightContainer: {
    display: 'flex',
    padding: 20,
  },
  descriptionCard: {
    width: '100%',
    height: '100%',
  },
  membersCard: {
    width: '100%',
  },
  addMember: {
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.dark,
    borderWidth: 1,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  nameLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  descriptionHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  projectCard: {
    display: 'flex',
  },
}));
