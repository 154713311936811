import { useMutation } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { IconButton, Box, ClickAwayListener, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { SET_TASK_HOURS_ESTIMATED } from 'graphql/mutations/Projects/setTaskHoursEstimated';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';

interface HoursEstimatedCellProps {
  parentId?: string | null | undefined;
  taskId: string;
  hoursEstimated: number | null | undefined;
}

export const HoursEstimatedCell: React.FC<HoursEstimatedCellProps> = ({
  parentId,
  taskId,
  hoursEstimated,
}) => {
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [edit, setEdit] = useState<number | null>(null);

  const { currentUser } = useGlobalContext();
  const { currentProject } = useProjectContext();
  const notifier = useNotificationContext();

  const [changeHours] = useMutation(SET_TASK_HOURS_ESTIMATED);
  const [log] = useMutation(ADD_TO_LOG);

  const handleClickAway = () => {
    if (edit) {
      changeHours({
        variables: {
          projectId: currentProject?.id,
          parentId: parentId,
          taskId: taskId,
          hours: edit,
        },
      })
        .then(() => {
          log({
            variables: {
              data: {
                type: `task-hoursEstimated`,
                madeBy: currentUser?.id,
                teamId: currentProject?.teamId,
                projectId: currentProject?.id,
                description: `Endret estimert timebruk på en oppgave`,
              },
            },
          });
        })
        .catch(() => {
          notifier.addAlert('name-change-error', {
            type: 'error',
            message: 'Kunne ikke endre navn på gjøremål, vennligs prøv igjen',
            onClose: () => notifier.hideAlert('name-change-error'),
            autoHide: true,
          });
        })
        .finally(() => setEdit(null));
    }
  };

  const classes = useEstimatedHoursStyles();

  const hoursNotSet =
    !hoursEstimated && hoursEstimated !== 0 && !edit && edit !== 0;
  return (
    <Box
      onClick={hoursNotSet ? () => setEdit(0) : undefined}
      onMouseOver={() => setInFocus(true)}
      onMouseOut={() => setInFocus(false)}
      sx={{
        '&:hover': { cursor: 'pointer' },
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {hoursNotSet ? (
        inFocus ? (
          <IconButton disabled size='small'>
            <Add className={classes.editIcon} fontSize='small' />
          </IconButton>
        ) : null
      ) : (
        <Box className={classes.root}>
          {edit || edit === 0 ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <input
                min={0}
                step='0.5'
                value={edit}
                type='number'
                onChange={(e) => setEdit(parseFloat(e.target.value))}
                className={classes.nameInput}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleClickAway();
                }}
              />
            </ClickAwayListener>
          ) : (
            <Typography
              fontSize={12}
              className={classes.hourLabel}
              onClick={() => setEdit(hoursEstimated ?? 0)}
            >
              {hoursEstimated}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const useEstimatedHoursStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%,',
  },
  nameInput: {
    width: '90%',
    padding: 2,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  hourLabel: {
    textAlign: 'center',
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderRadius: 5,
      borderStyle: 'solid',
      borderWidth: 0.1,
      borderColor: '#ced4da',
    },
  },
}));
