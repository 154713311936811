import { Box, Grid } from '@mui/material';
import { Calendar } from 'components/widgets/DashboardWidgets/Calendar';
import { CustomerDirectory } from 'components/widgets/DashboardWidgets/CustomerDirectory';
import { Monday } from 'components/widgets/DashboardWidgets/Monday/Monday';
import { PowerOffice } from 'components/widgets/DashboardWidgets/PowerOffice';
import { HourSummary } from 'components/widgets/DashboardWidgets/Poweroffice/HourSummary';
import { ProjectsProgress } from 'components/widgets/DashboardWidgets/Projects/ProjectsProgress';
import { Statz } from 'components/widgets/DashboardWidgets/Statz';
import { TaskStatistics } from 'components/widgets/DashboardWidgets/Tasks/TaskStatistics';
import { UpcomingTasks } from 'components/widgets/DashboardWidgets/Tasks/UpcomingTasks';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { WidgetID } from 'types/widgets';

const renderWidget = (id: WidgetID) => {
  switch (id) {
    case 'STATZ':
      return <Statz />;
    case 'HOUR_SUMMARY':
      return <HourSummary />;
    case 'POWEROFFICE':
      return <PowerOffice />;
    case 'MONDAY':
      return <Monday />;
    case 'CALENDAR':
      return <Calendar />;
    case 'CUSTOMERDIR':
      return <CustomerDirectory />;
    case 'UPCOMING_TASKS':
      return <UpcomingTasks />;
    case 'TASK_STATS':
      return <TaskStatistics />;
    case 'PROJECTS_PROGRESS':
      return <ProjectsProgress />;
  }
};

export const WidgetGrid: React.FC = () => {
  const { currentSettings } = useGlobalContext();
  return (
    <Box display={'flex'} width='100%' justifyContent={'center'}>
      <Grid
        container
        justifyContent={'flex-start'}
        sx={{ maxWidth: '100%', maxHeight: '100%' }}
      >
        {currentSettings?.dashboard?.widgets?.map((widget) =>
          renderWidget(widget.id)
        )}
      </Grid>
    </Box>
  );
};
