import { useMutation } from '@apollo/client';
import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Tooltip,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridColumns,
  GridRowClassNameParams,
  GridRowParams,
  GridRowsProp,
  GRID_REORDER_COL_DEF,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { DELETE_TASKS } from 'graphql/mutations/Projects/deleteTasks';
import { DELETE_SUBTASKS } from 'graphql/mutations/Projects/deleteSubTasks';
import React, {
  MouseEvent,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { Task } from 'types/task';
import { AddTaskModal } from '../AddTaskModal';
import { AddSubTaskModal } from '../AddSubTaskModal';
import { OwnerPopper } from './Poppers/OwnerPopper';
import { PriorityPopper } from './Poppers/PriorityPopper';
import { StatusPopper } from './Poppers/StatusPopper';
import { DeadlineCell } from './TableCells/DeadlineCell';
import { HoursCell } from './TableCells/HoursCell';
import { HoursEstimatedCell } from './TableCells/HoursEstimatedCell';
import { OwnerCell } from './TableCells/OwnerCell';
import { PriorityCell } from './TableCells/PriorityCell';
import { StatusCell } from './TableCells/StatusCell';
import { SET_TASK_NAME } from 'graphql/mutations/Projects/setTaskName';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';

export const Tasks: React.FC = () => {
  const [addTaskOpen, setAddTaskOpen] = useState<boolean>(false);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [popperAnchor, setPopperAnchor] = useState<HTMLTableCellElement | null>(
    null
  );
  const [popperCell, setPopperCell] = useState<ReactElement<any | any>>();
  const [deletingTask, setDeletingTask] = useState<string>();
  const [addSubTask, setAddSubTask] = useState<any>({
    parentId: null,
    open: false,
  });
  const [hideFinishedTasks, setHideFinishedTasks] = useState<boolean>(false);

  const { currentProject } = useProjectContext();
  const { currentUser, currentTeam } = useGlobalContext();
  const notifier = useNotificationContext();

  const [deleteTasks] = useMutation(DELETE_TASKS);
  const [deleteSubTasks] = useMutation(DELETE_SUBTASKS);
  const [changeTaskName] = useMutation(SET_TASK_NAME);

  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const classes = useTaskStyles();

  const handleHideFinishedTasks = useCallback(() => {
    setHideFinishedTasks((prev) => !prev);
  }, []);

  const handleDelete = useCallback(
    (parentId: string, task: Task) => async () => {
      setDeletingTask(task._id);
      if (task?.__typename === 'SubTask') {
        await deleteSubTasks({
          variables: {
            projectId: currentProject?.id,
            task: parentId,
            subTasks: [task._id],
          },
        });
      } else {
        await deleteTasks({
          variables: {
            projectId: currentProject?.id,
            tasks: [task._id],
          },
        });
      }
      setDeletingTask(undefined);
      notifier.addAlert('successfully-deleted', {
        type: 'success',
        message: 'Oppgaven ble slettet',
        onClose: () => notifier.hideAlert('successfully-deleted'),
        autoHide: true,
      });
      log({
        variables: {
          data: {
            type: 'tasks-deleted',
            madeBy: currentUser?.id,
            projectId: currentProject?.id,
            teamId: currentTeam?.id,
            description: 'Slettet en oppgave',
          },
        },
      });
      if (task.owner && task.owner !== currentUser?.id) {
        addNotification({
          variables: {
            data: {
              type: `task-deleted`,
              sender: currentUser?.id,
              receivers: [task.owner],
              message: 'fjernet en av dine oppgaver',
              subject: currentProject?.name,
            },
          },
        });
      }
    },
    [
      addNotification,
      currentProject?.id,
      currentProject?.name,
      currentTeam?.id,
      currentUser?.id,
      deleteSubTasks,
      deleteTasks,
      log,
      notifier,
    ]
  );

  const resetPopper = useCallback(() => {
    setPopperAnchor(null);
    setPopperCell(undefined);
    setPopperOpen(false);
  }, []);

  const toggleStatusPopper = useCallback(
    (id: string, parentId?: string | null | undefined) =>
      (event: MouseEvent<HTMLTableCellElement>) => {
        setPopperAnchor(event.currentTarget);
        setPopperCell(
          <StatusPopper parentId={parentId} taskId={id} onClose={resetPopper} />
        );
        setPopperOpen(true);
      },
    [resetPopper]
  );

  const togglePriorityPopper = useCallback(
    (id: string, parentId?: string | null | undefined) =>
      (event: MouseEvent<HTMLTableCellElement>) => {
        setPopperAnchor(event.currentTarget);
        setPopperCell(
          <PriorityPopper
            parentId={parentId}
            taskId={id}
            onClose={resetPopper}
          />
        );
        setPopperOpen(true);
      },
    [resetPopper]
  );

  const toggleOwnerPopper = useCallback(
    (id: string, parentId?: string | null | undefined) =>
      (event: MouseEvent<HTMLTableCellElement>) => {
        setPopperAnchor(event.currentTarget);
        setPopperCell(
          <OwnerPopper parentId={parentId} taskId={id} onClose={resetPopper} />
        );
        setPopperOpen(true);
      },
    [resetPopper]
  );

  const handleEditName = useCallback(
    (taskId: string, name: string, parentId?: string | null | undefined) => {
      changeTaskName({
        variables: {
          projectId: currentProject?.id,
          parentId: parentId,
          taskId: taskId,
          name: name,
        },
      })
        .then(() => {
          log({
            variables: {
              data: {
                type: `task-name`,
                madeBy: currentUser?.id,
                teamId: currentProject?.teamId,
                projectId: currentProject?.id,
                description: `Endret navn på en oppgave`,
              },
            },
          });
        })
        .catch(() => {
          notifier.addAlert('name-change-error', {
            type: 'error',
            message: 'Kunne ikke endre navn på gjøremål, vennligs prøv igjen',
            onClose: () => notifier.hideAlert('name-change-error'),
            autoHide: true,
          });
        });
    },
    [
      changeTaskName,
      currentProject?.id,
      currentProject?.teamId,
      currentUser?.id,
      log,
      notifier,
    ]
  );

  const rows: GridRowsProp[] = useMemo(
    () =>
      currentProject?.tasks
        ?.filter((task: Task) =>
          hideFinishedTasks ? task.status !== 'Done' : true
        )
        ?.flatMap((task: Task) => [
          { path: `${task._id}`, id: task?._id, ...task },
          ...(task.subTask ?? [])
            ?.filter((task: Task) =>
              hideFinishedTasks ? task.status !== 'Done' : true
            )
            ?.map((subtask: Task) => ({
              path: `${task?._id}/${subtask?._id}`,
              id: subtask?._id,
              parent: task?._id,
              ...subtask,
            })),
        ]),

    [currentProject?.tasks, hideFinishedTasks]
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40,
      },
      {
        field: 'name',
        headerName: 'Gjøremål',
        flex: 1,
        maxWidth: 375,
        editable: true,
        renderCell: (params) => (
          <Typography
            sx={{ textIndent: params.row.parent ? 30 : 0, fontSize: 12 }}
          >
            {params.row.name}
          </Typography>
        ),
      },
      {
        headerName: 'Ansvarlig',
        field: 'owner',
        flex: 0.25,
        renderCell: (params) => (
          <OwnerCell
            owner={params.row.owner}
            onClick={toggleOwnerPopper(params.row._id, params?.row?.parent)}
          />
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 0.25,
        renderCell: (params) => (
          <StatusCell
            status={params.row.status}
            onClick={toggleStatusPopper(params.row._id, params?.row?.parent)}
            className={classes.foldableCell}
          />
        ),
      },
      {
        headerName: 'Prioritet',
        field: 'priority',
        flex: 0.25,
        renderCell: (params) => (
          <PriorityCell
            priority={params.row.priority}
            onClick={togglePriorityPopper(params.row._id, params?.row?.parent)}
            className={classes.foldableCell}
          />
        ),
      },
      {
        headerName: 'Deadline',
        field: 'deadline',
        flex: 0.5,
        renderCell: (params) => (
          <DeadlineCell
            parentId={params?.row?.parent}
            deadline={params.row.deadline}
            taskId={params.row._id}
          />
        ),
      },
      {
        headerName: 'Timer brukt',
        field: 'hours',
        flex: 0.25,
        renderCell: (params) => (
          <HoursCell
            parentId={params?.row?.parent}
            hours={params.row.hours}
            taskId={params.row._id}
          />
        ),
      },
      {
        headerName: 'Timer estimert',
        field: 'hoursEstimated',
        flex: 0.25,
        renderCell: (params) => (
          <HoursEstimatedCell
            parentId={params?.row?.parent}
            hoursEstimated={params.row.hoursEstimated}
            taskId={params.row._id}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => [
          deletingTask !== params.row._id ? (
            <>
              <Tooltip title='Slett'>
                <GridActionsCellItem
                  icon={<Delete color='error' />}
                  label='Slett'
                  onClick={handleDelete(params.row.parent ?? '', params.row)}
                ></GridActionsCellItem>
              </Tooltip>
              {!params.row.parent && (
                <Tooltip title='Legg til underoppgave'>
                  <GridActionsCellItem
                    icon={<Add color='success' />}
                    label='Legg til underoppgave'
                    onClick={() =>
                      setAddSubTask({
                        parentId: params.row._id,
                        open: true,
                      })
                    }
                  ></GridActionsCellItem>
                </Tooltip>
              )}
            </>
          ) : (
            <CircularProgress />
          ),
        ],
      },
    ],
    [
      classes.foldableCell,
      deletingTask,
      handleDelete,
      toggleOwnerPopper,
      togglePriorityPopper,
      toggleStatusPopper,
    ]
  );

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = useCallback(
    (row) => row.path.split('/'),
    []
  );

  const groupingColDef: DataGridProProps['groupingColDef'] = useMemo(
    () => ({
      headerName: '',
      valueGetter: (params) => /* params.row?.name */ '',
      flex: 0.25,
      cellClassName: 'borderless',
      /* flex: 1,
      maxWidth: 400,
      editable: true, */
    }),
    []
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams) =>
      params.row.__typename === 'SubTask' ? 'sub' : 'parent',
    []
  );

  return (
    <Box className={classes.root} display='flex' flexDirection='column'>
      <AddTaskModal open={addTaskOpen} onClose={() => setAddTaskOpen(false)} />
      <AddSubTaskModal
        taskId={addSubTask.parentId}
        open={addSubTask.open}
        onClose={() => setAddSubTask({ parentId: null, open: false })}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant='contained'
          endIcon={<Add />}
          onClick={() => setAddTaskOpen(true)}
          color='secondary'
        >
          Legg til gjøremål
        </Button>
        <FormControlLabel
          label={'Skjul fullførte oppgaver'}
          control={
            <Checkbox
              value={hideFinishedTasks}
              onChange={handleHideFinishedTasks}
            />
          }
        />
      </Box>
      <Box sx={{ width: '100%', height: 'auto' }}>
        <Popper
          id={popperOpen ? 'simple-popper' : undefined}
          open={popperOpen}
          placement='bottom'
          anchorEl={popperAnchor}
        >
          <ClickAwayListener onClickAway={() => resetPopper()}>
            <Paper
              sx={{
                position: 'relative',
                mt: '10px',
                '&::before': {
                  backgroundColor: 'white',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 12,
                  height: 12,
                  top: -6,
                  transform: 'rotate(45deg)',
                  left: 'calc(50% - 6px)',
                  borderColor: '#ced4da',
                  borderTopStyle: 'solid',
                  borderLeftStyle: 'solid',
                  borderWidth: 0.1,
                },
              }}
            >
              {popperCell && popperCell}
            </Paper>
          </ClickAwayListener>
        </Popper>
        <Paper sx={{ width: '100%', height: 'auto', mb: 2, mt: 2, flex: 1 }}>
          <DataGridPro
            /*     rowReordering */
            experimentalFeatures={{ newEditingApi: true }}
            getRowClassName={getRowClassName}
            isRowSelectable={() => false}
            autoHeight
            treeData
            columns={columns}
            rows={rows}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            processRowUpdate={(newRow) => {
              handleEditName(newRow?._id, newRow?.name, newRow?.parent);
              return newRow;
            }}
            onProcessRowUpdateError={(error) => console.error(error)}
            /* showCellRightBorder */
            sx={{
              padding: 0,
              '.borderless': {
                borderRightStyle: 'none !important',
              },
              '.MuiDataGrid-cell': {
                padding: 0,
                /*   borderRightStyle: 'solid',
                borderRightWidth: 0.1,
                borderColor: '#ced4da', */
              },
              '.parent': {
                fontSize: 12,
              },
              '.sub': {
                fontSize: 12,
                backgroundColor: 'rgba(255, 235, 156, 0.5)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 235, 156, 1)',
                },
              },
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
};

const useTaskStyles = makeStyles((theme) => ({
  '@keyframes foldCorner': {
    '0%': {},
    '100%': {
      content: `''`,
      position: 'absolute',
      top: '0%',
      right: '0%',
      width: 0,
      height: 0,
      borderBottom: '10px solid #eee',
      borderRight: '10px solid #fff',
      boxShadow: '2px 2px 2px rgba(0,0,0,0.3)',
    },
  },
  root: {
    height: 'auto',
    '& .MuiTableCell-root': {
      borderStyle: 'solid',
      borderWidth: 0.1,
      borderColor: '#ced4da',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '100%',
      position: 'relative',
      boxShadow: '2px 2px 2px rgba(0,0,0,0.8)',
    },

    '& .MuiTableRow-root': {
      height: 50,
    },
  },
  foldableCell: {
    '&:hover': {
      cursor: 'pointer',
      '&::before': {
        content: `''`,
        animation: `$foldCorner 300ms ${theme.transitions.easing.easeIn}`,
        animationFillMode: 'forwards',
      },
    },
  },
}));
