import { gql } from '@apollo/client';

export const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllNotifications($userId: ID) {
    readAllNotifications(userId: $userId) {
      id
      message
      type
      timestamp
      sender
      readBy
      subject
    }
  }
`;
