import { useMutation, useQuery } from '@apollo/client';
import {
  IconButton,
  Badge,
  Popover,
  List,
  Divider,
  Card,
  Box,
  Typography,
  Button,
  Avatar,
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { NOTIFICATIONS } from 'graphql/queries/Notifications/notifications';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import StatzAvatar from 'assets/images/favicon.ico';
import { displayName } from 'utils/Stringparser';
import { READ_NOTIFICATION } from 'graphql/mutations/Notification/readNotification';
import { NOTIFICATION_SUBSCRIPTION } from 'graphql/subscriptions/NotificationSubscription';
import { READ_ALL_NOTIFICATIONS } from 'graphql/mutations/Notification/readAllNotifications';

const INITIAL_LIMIT = 5;
const MAX_LIMIT = 100;

export const Notifications: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentLimit, setCurrentLimit] = useState<number>(INITIAL_LIMIT);
  const { currentUser, teamMembers } = useGlobalContext();

  const {
    data: notificationsData,
    subscribeToMore,
    refetch,
  } = useQuery(NOTIFICATIONS, {
    variables: {
      userId: currentUser?.id,
      limit: 20,
    },
    onCompleted(data) {
      console.log('Her skjer det noe gitt');
    },
  });

  const [readNotification] = useMutation(READ_NOTIFICATION);
  const [readAllNotifications] = useMutation(READ_ALL_NOTIFICATIONS);

  const handleShowNotifications = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = () => setAnchorEl(null);

  const renderTimeSince = useCallback((timestamp: string) => {
    const today = new Date().getTime();
    let startDate = new Date(timestamp).getTime();

    const days = (today - startDate) / (1000 * 60 * 60 * 24);
    const hours = (Math.abs(today - startDate) / (1000 * 60 * 60)) % 24;
    const minutes = (Math.abs(today - startDate) / (1000 * 60)) % 60;

    if (days > 1) {
      return `${Math.floor(days)} days ago`;
    } else if (hours > 1) {
      return `${Math.floor(hours)} hours ago`;
    } else if (minutes > 30) {
      return '< 1 hour ago';
    } else if (minutes > 10) {
      return '< 30 min ago';
    } else if (minutes > 1) {
      return '< 10 min ago';
    } else {
      return '< 1 min ago';
    }
  }, []);

  const showNotifications = Boolean(anchorEl);
  const newNotificationsCount =
    notificationsData?.notifications?.filter(
      (not: any) => !not?.readBy?.includes(currentUser?.id)
    )?.length ?? 0;
  const totalNotificationsCount = notificationsData?.notifications?.length ?? 0;

  const handleClick = useCallback(
    (notification) => () => {
      if (notification?.readBy?.includes(currentUser?.id)) return;
      readNotification({
        variables: {
          id: notification.id,
          userId: currentUser?.id,
        },
      });
    },
    [currentUser?.id, readNotification]
  );

  const handleReadAll = useCallback(() => {
    readAllNotifications({
      variables: {
        userId: currentUser?.id,
      },
    }).then(() => refetch());
  }, [currentUser?.id, readAllNotifications, refetch]);

  const handleShowMore = useCallback(() => {
    setCurrentLimit((prev) => prev + INITIAL_LIMIT);
  }, []);

  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: NOTIFICATION_SUBSCRIPTION,
      variables: {
        userId: currentUser?.id,
        limit: MAX_LIMIT,
      },
      updateQuery: (prev, { subscriptionData }) => {
        let newNotification = subscriptionData?.data?.notifications;
        if (!newNotification) return prev;
        else if (
          !prev?.notifications?.find(
            (not: any) => not.id === newNotification.id
          )
        ) {
          return Object.assign(
            {},
            {
              notifications: [newNotification, ...prev.notifications],
            }
          );
        } else
          return Object.assign(
            {},
            {
              notifications: prev.notifications?.map((not: any) => {
                if (not.id === newNotification.id) return newNotification;
                else return not;
              }),
            }
          );
      },
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  return (
    <>
      <IconButton onClick={handleShowNotifications}>
        <Badge badgeContent={newNotificationsCount} color={'primary'}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={showNotifications}
        onClose={handleClose}
      >
        <Card
          sx={{
            width: 350,
            minHeight: 200,
            maxHeight: 350,
            height: 'auto',
            position: 'relative',
            overflow: 'auto',
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems={'center'}
            sx={{ px: 2, pt: 2 }}
          >
            <Typography variant='h6' fontSize={26}>
              Varsler
            </Typography>
            <Button
              variant='text'
              sx={{
                fontSize: 10,
              }}
              onClick={handleReadAll}
            >
              Merk alle som lest
            </Button>
          </Box>
          <Divider />
          <List sx={{ height: '100%', width: '100%', p: 0 }}>
            {totalNotificationsCount > 0 ? (
              notificationsData?.notifications
                ?.slice(0, currentLimit)
                ?.map((not: any) => (
                  <>
                    <Box
                      display='flex'
                      alignItems='center'
                      onClick={handleClick(not)}
                      sx={{
                        borderLeft: !not.readBy?.includes(currentUser?.id)
                          ? 'solid'
                          : 'none',
                        borderColor: 'primary.main',
                        px: 2,
                        backgroundColor: !not.readBy?.includes(currentUser?.id)
                          ? 'rgba(244, 85, 0, 0.05)'
                          : 'default',
                        cursor: !not.readBy?.includes(currentUser?.id)
                          ? 'pointer'
                          : 'default',
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ width: 30, height: 30, mr: 2 }}>
                        <Avatar
                          src={
                            teamMembers.find((user) => user.id === not.sender)
                              ?.avatar ?? StatzAvatar
                          }
                          variant='rounded'
                          sx={{
                            width: 'inherit',
                            height: 'inherit',
                            backgroundColor: 'black',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: 'black',
                          }}
                        />
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='column'
                        sx={{
                          color: !not.readBy?.includes(currentUser?.id)
                            ? 'primary.main'
                            : 'default',
                        }}
                      >
                        <Typography fontSize={14}>
                          <strong>
                            {not.sender
                              ? `${displayName(
                                  teamMembers?.find(
                                    (user) => user.id === not.sender
                                  )
                                )} `
                              : ''}
                          </strong>
                          {not.message}
                        </Typography>
                        <Typography
                          fontSize={12}
                          variant='caption'
                          color={'GrayText'}
                        >
                          {`${renderTimeSince(not.timestamp)} - ${not.subject}`}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />
                  </>
                ))
            ) : (
              <Box
                sx={{
                  height: 200,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography color='GrayText'>Du har ingen varsler</Typography>
              </Box>
            )}
          </List>
          {totalNotificationsCount > currentLimit &&
            currentLimit + INITIAL_LIMIT <= MAX_LIMIT && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant='text'
                  sx={{
                    alignSelf: 'center',
                  }}
                  onClick={handleShowMore}
                >
                  Vis flere
                </Button>
              </Box>
            )}
        </Card>
      </Popover>
    </>
  );
};
