import { gql } from '@apollo/client';

export const NOTIFICATIONS = gql`
  query Notifications($userId: ID, $teamId: ID, $limit: Int) {
    notifications(userId: $userId, teamId: $teamId, limit: $limit) {
      id
      message
      type
      timestamp
      readBy
      sender
      subject
    }
  }
`;
