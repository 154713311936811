import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { CustomDateInput } from 'components/CustomDatePicker/CustomDateInput';
import { CustomTextField } from 'components/CustomTextField/CustomTextField';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';
import { CREATE_TASK } from 'graphql/mutations/Projects/createTask';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { Task } from 'types/task';
import { displayName } from 'utils/Stringparser';

export interface AddTaskModalProps {
  open: boolean;
  onClose: () => void;
  status?: string;
  onSave?: (task: Task) => void;
}

export const AddTaskModal: React.FC<AddTaskModalProps> = ({
  open,
  onClose,
  status,
  onSave,
}) => {
  const { projectMembers, currentProject } = useProjectContext();
  const { currentTeam, currentUser } = useGlobalContext();
  const notifier = useNotificationContext();

  const [name, setName] = useState<string>('');
  const [owner, setOwner] = useState<string>('ingen');
  const [priority, setPriority] = useState<string>('ingen');
  const [deadline, setDeadline] = useState<Date | null>(null);
  const [description, setDescription] = useState<string>('');
  const [hoursEstimated, setHoursEstimated] = useState<number>();

  const [createTask, { loading }] = useMutation(CREATE_TASK);
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  const [log] = useMutation(ADD_TO_LOG);

  const handleClose = () => {
    setName('');
    setOwner('ingen');
    setPriority('ingen');
    setDeadline(null);
    setDescription('');
    setHoursEstimated(undefined);
    onClose();
  };

  const renderInitials = (memberId: string) => {
    let member = projectMembers.find((x) => x.id === memberId);
    return `${member?.firstName?.[0] ?? ''}${member?.lastName?.[0] ?? ''}`;
  };

  const handleSaveTask = () => {
    let priorityLabel = currentTeam?.settings?.priorityLabels.find(
      (label) => label.name === priority
    );
    let taskData = {
      name: name,
      description: description,
      priority: priorityLabel?.name,
      status: status ?? null,
      deadline: deadline ?? null,
      hoursEstimated: hoursEstimated ?? null,
      hours: 0,
      owner: owner && owner !== 'ingen' ? owner : null,
    };
    createTask({
      variables: {
        projectId: currentProject?.id,
        data: taskData,
      },
    })
      .then((res) => {
        console.log(res?.data?.createTask);
        if (onSave) onSave(res?.data?.createTask);
        notifier.addAlert('task-saved', {
          type: 'success',
          message: 'Gjøremålet ble lagret',
          onClose: () => notifier.hideAlert('task-saved'),
        });
        log({
          variables: {
            data: {
              type: 'task-add',
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: 'La til en ny oppgave',
            },
          },
        });
        if (taskData.owner && taskData.owner !== currentUser?.id) {
          addNotification({
            variables: {
              data: {
                type: 'TEST',
                message: 'la til en oppgave til deg',
                sender: currentUser?.id,
                receivers: [taskData.owner],
                subject: currentProject?.name,
              },
            },
          });
        }
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        notifier.addAlert('task-save-failed', {
          type: 'error',
          message: err.message,
          onClose: () => notifier.hideAlert('task-save-failed'),
        });
      });
  };
  return (
    <Modal open={open}>
      <Box
        display='flex'
        justifyContent={'center'}
        alignItems='center'
        width={'100%'}
        height='100%'
      >
        <Paper sx={{ position: 'relative', width: 350, padding: 3 }}>
          <IconButton
            size='small'
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <Typography variant='h6' mb={2}>
            Nytt gjøremål
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl size='small' required variant='standard' fullWidth>
                <InputLabel shrink>Navn på Gjøremål</InputLabel>
                <CustomTextField
                  value={name}
                  placeholder='Nytt gjøremål'
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' fullWidth>
                <InputLabel>Ansvarlig Person</InputLabel>
                <Select
                  value={owner}
                  label='Anvsarlig person'
                  size='small'
                  placeholder='Velg ansvarlig person'
                  input={<CustomTextField />}
                  onChange={(e) => setOwner(e.target.value)}
                >
                  <MenuItem value='ingen'>Ingen valgt</MenuItem>
                  {projectMembers?.map((member) => {
                    return (
                      <MenuItem value={member?.id}>
                        <Box display='flex' alignItems='center'>
                          <Avatar
                            sx={{
                              height: 24,
                              width: 24,
                              fontSize: 12,
                              mr: 1,
                              bgcolor: member.color,
                            }}
                            src={member?.avatar}
                          >
                            {renderInitials(member.id)}
                          </Avatar>
                          {displayName(member)}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink>Prioritet</InputLabel>
                <Select
                  value={priority}
                  fullWidth
                  label='Prioritet'
                  size='small'
                  placeholder='Prioritet'
                  input={<CustomTextField />}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value='ingen'>Ingen valgt</MenuItem>
                  {currentTeam?.settings?.priorityLabels?.map((label) => {
                    return (
                      <MenuItem value={label.name}>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='space-between'
                          flex={1}
                        >
                          {label.name}
                          <Box
                            sx={{
                              bgcolor: label.color,
                              width: 20,
                              height: 20,
                              borderColor: 'black',
                              borderStyle: 'solid',
                              borderWidth: 0.1,
                            }}
                          />
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink>Antatt timebruk</InputLabel>
                <CustomTextField
                  type='number'
                  value={hoursEstimated}
                  placeholder='Ikke definert'
                  onChange={(e) => setHoursEstimated(parseInt(e.target.value))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl size='small' variant='standard' fullWidth>
                  <InputLabel shrink>Deadline</InputLabel>
                  <DesktopDatePicker
                    inputFormat='dd/MM/yyyy'
                    value={deadline}
                    onChange={(date) => setDeadline(date)}
                    renderInput={(params) => (
                      <CustomDateInput {...params} size='small' fullWidth />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink>Beskrivelse</InputLabel>
                <CustomTextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder='Eventuelle detaljer angående gjøremålet...'
                  multiline
                  rows={3}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={name?.length === 0}
                onClick={handleSaveTask}
                variant='contained'
                endIcon={loading && <CircularProgress size={16} />}
              >
                Lagre
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};
