import { gql } from '@apollo/client';

export const GET_PROJECT_MEMBERS = gql`
  query GetProjectMembers($ids: [ID]) {
    getProjectMembers(ids: $ids) {
      id
      firstName
      lastName
      email
      phone
      color
      avatar
    }
  }
`;
