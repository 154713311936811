import React, { useEffect } from "react";
import { Alert as MUIAlert, AlertTitle, Snackbar } from "@mui/material";
import { AlertProps } from "../../types/alert";

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  autoHide,
  autoHideDuration,
  title,
  onClose,
}) => {
  useEffect(() => {
    if (autoHide || autoHideDuration) {
      setTimeout(() => {
        onClose();
      }, autoHideDuration ?? 3000);
    }
  }, [autoHide, autoHideDuration, onClose]);

  return (
    <Snackbar
      open={true}
      autoHideDuration={autoHide ? autoHideDuration ?? 3000 : undefined}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
    >
      {type && (
        <MUIAlert severity={type} onClose={onClose}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </MUIAlert>
      )}
    </Snackbar>
  );
};
