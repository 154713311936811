import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
  mutation CreateProject($data: ProjectInput) {
    createProject(data: $data) {
      id
      name
      mondayWorkspace
      mondayBoard
      teamId
      powerofficeProject
      description
      startDate
      endDate
      members
      projectLeaders
      createdByName
      createdById
      createdAt
      archived
    }
  }
`;
