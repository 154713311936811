import { useCallback, useMemo, useState } from 'react';
import { createGenericContext } from './createGenericContext';

interface DashboardContextType {
  currentProject: string;
  setCurrentProject: (projectId: string) => void;
}

export const [useDashboardContext, BareDashboardContextProvider] =
  createGenericContext<DashboardContextType>();

export const DashboardContextProvider: React.FC = ({ children }) => {
  const [currentProject, setCurrentProject] = useState<string>('*');

  const handleSetCurrentProject = useCallback((project: string) => {
    setCurrentProject(project);
  }, []);

  const value: DashboardContextType = useMemo(
    () => ({
      currentProject: currentProject,
      setCurrentProject: handleSetCurrentProject,
    }),
    [currentProject, handleSetCurrentProject]
  );
  return (
    <BareDashboardContextProvider value={value}>
      {children}
    </BareDashboardContextProvider>
  );
};
