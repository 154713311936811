import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer as MuiDrawer,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGlobalContext } from 'state/context/GlobalContext';
import Dashboard from '@mui/icons-material/Dashboard';
import {
  Logout,
  Person,
  /* Settings, */ Workspaces,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'pages/routes';
import DefaultAvatar from 'assets/images/user.jpg';
import { useMutation, useQuery } from '@apollo/client';
import { LOGOUT } from 'graphql/mutations';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { Team } from 'types/team';
import { TEAMS } from 'graphql/queries/Team/Teams';

export const DRAWER_WIDTH = 240;

export const Drawer: React.FC = () => {
  const classes = useDrawerStyles();
  const { currentUser, currentTeam, displayName, clearAuth, setTeam } =
    useGlobalContext();
  const notifier = useNotificationContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [logout] = useMutation(LOGOUT);
  const { data: teamsData } = useQuery(TEAMS, {
    variables: { ids: currentUser?.teams },
  });

  const handleLogout = () => {
    logout()
      .then(() => {
        clearAuth();
      })
      .catch((err) => {
        notifier.addAlert('logout', {
          title: 'Noe gikk galt',
          message: err?.message ?? 'Kunne ikke logge ut',
          onClose: () => notifier.hideAlert('logout'),
          type: 'error',
        });
      });
  };

  const handleTeamChange = (e: SelectChangeEvent) => {
    let team = teamsData?.teams?.find(
      (x: Team) => x.id === e.target.value
    ) as Team;
    setTeam(team);
    if (
      location.pathname?.includes(routes.projects) &&
      location.pathname?.length > routes.projects?.length
    ) {
      navigate(routes.projects);
    }
  };

  return (
    <MuiDrawer
      className={classes.root}
      open={true}
      variant='persistent'
      anchor='left'
    >
      <Box className={classes.topContainer}>
        <Avatar
          src={currentUser?.avatar ?? DefaultAvatar}
          alt=''
          className={classes.avatar}
        />
        <Typography className={classes.username}>{displayName}</Typography>
        {currentUser?.teams && currentUser?.teams?.length > 1 && (
          <FormControl sx={{ p: 2 }} fullWidth>
            <FormLabel
              sx={{
                color: '#fff',
                '&:active': {
                  color: 'red',
                },
              }}
            >
              Velg team
            </FormLabel>
            <Select
              value={currentTeam?.id}
              fullWidth
              size='small'
              onChange={handleTeamChange}
              sx={{
                backgroundColor: '#fff',
                borderColor: '#fff',
              }}
            >
              {teamsData?.teams?.map((team: Team) => {
                return <MenuItem value={team.id}>{team?.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        )}
      </Box>
      <Divider />
      <List sx={{ padding: 0, flex: 1 }}>
        <ListItem disablePadding className={classes.listItem}>
          <ListItemButton
            onClick={() => navigate(routes.dashboard)}
            selected={location.pathname === routes.dashboard}
          >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText color=''>Oversikt</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className={classes.listItem}>
          <ListItemButton
            onClick={() => navigate(routes.projects)}
            selected={location.pathname.includes(routes.projects)}
          >
            <ListItemIcon>
              <Workspaces />
            </ListItemIcon>
            <ListItemText>Prosjekter</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className={classes.listItem}>
          <ListItemButton
            onClick={() => navigate(routes.profile)}
            selected={location.pathname === routes.profile}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>Profil</ListItemText>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding className={classes.listItem}>
          <ListItemButton
            onClick={() => navigate(routes.settings)}
            selected={location.pathname === routes.settings}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText>Innstillinger</ListItemText>
          </ListItemButton>
        </ListItem> */}
      </List>
      <Box className={classes.bottomContainer}>
        <Button startIcon={<Logout />} onClick={handleLogout} color='secondary'>
          Logg ut
        </Button>
      </Box>
    </MuiDrawer>
  );
};

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.main,
      '& .MuiDivider-root': {
        borderColor: '#fff',
        opacity: 0.5,
      },

      '& .MuiOutlinedInput-root': {
        color: '#474444',
        background: 'linear-gradient(to bottom right, #fff, #f7f5c7)',
      },

      '& .MuiFormLabel-root': {
        color: '#fff',
      },
    },
  },
  topContainer: {
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  avatar: {
    height: 75,
    width: 75,
    marginBottom: 20,
  },
  username: {
    fontSize: 20,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    color: theme.palette.primary.contrastText,
  },

  listItem: {
    '& .Mui-selected': {
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-root': {
      color: '#fff',
    },
  },
  icon: {
    //color: '#fff',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
}));
