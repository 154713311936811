import { useMutation, useQuery } from '@apollo/client';
import { Add, Workspaces } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthenticateMonday } from 'components/AuthenticateMonday/AuthenticateMonday';
import { BreadcrumbesNavigation } from 'components/BreadcrumbsNavigation/BreadcrumbsNavigation';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';
import { CREATE_PROJECT } from 'graphql/mutations/Projects/createProject';
//import { GET_BOARDS_AND_WORKSPACES } from 'graphql/queries/Monday/getWorkspaces';
import { TEAM_MEMBERS } from 'graphql/queries/Team/TeamMembers';
import { routes } from 'pages/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';

export const CreateProject: React.FC = () => {
  const classes = useCreateProjectStyles();
  const { currentTeam, currentUser, displayName, currentSettings } =
    useGlobalContext();
  const notifier = useNotificationContext();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState<any>({
    name: '',
    description: '',
    mondayWorkspace: null,
    mondayBoard: null,
    //powerofficeProject: '',
    teamId: currentTeam?.id,
    startDate: null,
    endDate: null,
    members: currentTeam?.members,
    createdByName: displayName,
    createdById: currentUser?.id,
    projectLeaders: [currentUser?.id],
    hoursBudget: null,
    tasks: [
      {
        name: 'Kom i gang med prosjektet!',
      },
    ],
  });
  const [widgetSelections, setWidgetSelections] = useState<any>({
    monday: false,
  });
  const [saving, setSaving] = useState<boolean>(false);

  const [createProject] = useMutation(CREATE_PROJECT);
  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const { data: teamMemberData, loading: teamMembersLoading } = useQuery(
    TEAM_MEMBERS,
    { variables: { teamId: currentTeam?.id } }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectData({
      ...projectData,
      [e.target.name]:
        e.target.type !== 'number'
          ? e.target.value
          : parseFloat(e.target.value),
    });
  };

  const handleDateChange = (key: string, date: Date) => {
    setProjectData({ ...projectData, [key]: date });
  };

  const handleSave = () => {
    setSaving(true);
    createProject({
      variables: {
        data: projectData,
      },
    })
      .then((res) => {
        notifier.addAlert('project_added', {
          type: 'success',
          onClose: () => notifier.hideAlert('project_added'),
          message: 'Prosjektet ble lagret',
        });
        log({
          variables: {
            data: {
              type: 'project:created',
              madeBy: currentUser?.id,
              projectId: res?.data?.createProject?.id,
              teamId: currentTeam?.id,
              description: 'Opprettet prosjektet',
            },
          },
        });
        if (projectData?.members && projectData?.members?.length > 0) {
          addNotification({
            variables: {
              data: {
                type: 'project:created',
                message: 'la deg til i et nytt prosjekt',
                sender: currentUser?.id,
                receivers: projectData?.members?.filter(
                  (memb: string) => memb !== currentUser?.id
                ),
                subject: projectData?.name,
              },
            },
          });
        }
        navigate(routes.projects);
      })
      .catch((err) => {
        notifier.addAlert('project_add_err', {
          type: 'error',
          title: 'Noe gikk galt',
          message: err.message,
          onClose: () => notifier.hideAlert('project_add_fail'),
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Box className={classes.root}>
      <BreadcrumbesNavigation
        routes={[
          {
            label: 'Prosjekter',
            path: routes.projects,
            icon: <Workspaces fontSize='inherit' sx={{ mr: 0.5 }} />,
          },
          { label: 'Nytt prosjekt', path: routes.createProject },
        ]}
      />
      <Grid
        container
        justifyContent='space-evenly'
        spacing={3}
        className={classes.formContainer}
      >
        <Grid item md={12}>
          <Typography className={classes.title} variant='h4'>
            Lag nytt prosjekt
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name='name'
                required
                fullWidth
                size='small'
                label='Prosjektnavn'
                variant='outlined'
                value={projectData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='description'
                fullWidth
                label='Beskrivelse'
                value={projectData.description}
                onChange={handleInputChange}
                multiline
                rows={5}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={6}>
                <DesktopDatePicker
                  label='Prosjektstart'
                  inputFormat='dd/MM/yyyy'
                  value={projectData.startDate}
                  onChange={(date) => handleDateChange('startDate', date)}
                  renderInput={(params) => (
                    <TextField size='small' fullWidth {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DesktopDatePicker
                  label='Prosjektslutt'
                  inputFormat='dd/MM/yyyy'
                  value={projectData.endDate}
                  onChange={(date) => handleDateChange('endDate', date)}
                  renderInput={(params) => (
                    <TextField size='small' fullWidth {...params} />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <TextField
                name='hoursBudget'
                placeholder='Ikke definert'
                fullWidth
                size='small'
                label='Timebudsjett'
                variant='outlined'
                value={projectData.hoursBudget}
                type='number'
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl component='fieldset' variant='standard'>
            <FormLabel component='legend'>Velg prosjektmedarbeidere</FormLabel>
            <FormControlLabel
              label='Alle'
              control={
                <Checkbox
                  color='primary'
                  checked={
                    projectData.members.length === currentTeam?.members?.length
                  }
                  onChange={(e, checked) => {
                    let newMembers = !checked ? [] : currentTeam?.members;
                    setProjectData({ ...projectData, members: newMembers });
                  }}
                />
              }
            />
            <FormGroup row>
              {teamMembersLoading ? (
                <CircularProgress />
              ) : (
                teamMemberData?.teamMembers?.map((member: any) => {
                  return (
                    <FormControlLabel
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>{`${member?.firstName} ${member?.lastName}`}</Typography>
                          <Avatar
                            sx={{ ml: 3, height: 30, width: 30 }}
                            src={member?.avatar}
                          />
                        </Box>
                      }
                      control={
                        <React.Fragment>
                          <Checkbox
                            color='primary'
                            checked={projectData?.members?.includes(member?.id)}
                            onChange={(e, checked) => {
                              if (checked) {
                                setProjectData({
                                  ...projectData,
                                  members: [
                                    ...projectData?.members,
                                    member?.id,
                                  ],
                                });
                              } else {
                                setProjectData({
                                  ...projectData,
                                  members: [
                                    ...projectData?.members?.filter(
                                      (memb: any) => memb !== member?.id
                                    ),
                                  ],
                                });
                              }
                            }}
                          />
                        </React.Fragment>
                      }
                    />
                  );
                })
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <AuthenticateMonday
        open={
          widgetSelections.monday && !currentSettings?.dashboard?.monday?.userId
        }
        onClose={() => {
          setWidgetSelections({ ...widgetSelections, monday: false });
        }}
      />
      <Button
        className={classes.saveButton}
        startIcon={<Add />}
        onClick={handleSave}
        endIcon={
          saving && <CircularProgress sx={{ color: '#fff' }} size={20} />
        }
        variant='contained'
        color='secondary'
      >
        Lagre prosjekt
      </Button>
    </Box>
  );
};

export const useCreateProjectStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    padding: 25,
    flexDirection: 'column',
  },
  title: {
    marginTop: 20,
  },
  formContainer: {
    padding: 20,

    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
  mondaySetup: {
    marginLeft: 30,
  },
  saveButton: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
}));
