import { useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import PlaceholderImage from 'assets/images/project.png';
import { Add, Close, ContentCopy, Delete, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'pages/routes';
import { GET_PROJECTS_PROGRESS } from 'graphql/queries/Projects/getProjectsProgress';
import { DUPLICATE_PROJECT } from 'graphql/mutations/Projects/duplicateProject';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { DELETE_PROJECT } from 'graphql/mutations/Projects/deleteProject';

type Duplicate = {
  id: string;
  name: string;
  includeTasks: boolean;
};

export const ProjectsPage: React.FC = () => {
  const [duplicate, setDuplicate] = useState<Duplicate | null | undefined>();
  const [deleteModal, setDeleteModal] = useState<string | null | undefined>();

  const classes = useProjectsPageStyles();
  const {
    currentTeam,
    currentUser,
    allProjects,
    projectsLoading,
    refreshProjects,
  } = useGlobalContext();
  const notifier = useNotificationContext();
  const navigate = useNavigate();

  const { data: progress } = useQuery(GET_PROJECTS_PROGRESS, {
    variables: { userId: currentUser?.id, teamId: currentTeam?.id },
  });
  const [duplicateProject, { loading: duplicateLoading }] =
    useMutation(DUPLICATE_PROJECT);
  const [deleteProject] = useMutation(DELETE_PROJECT);

  const renderDateString = (datestring: string) => {
    let date = new Date(datestring);
    return date.toLocaleDateString();
  };

  const getStatus = (status: string | null) =>
    currentTeam?.settings?.statuses?.find((x) => x.name === status);

  const handleDuplicateProject = (
    projectId: string,
    name: string | null | undefined,
    includeTasks: boolean | undefined
  ) => {
    duplicateProject({
      variables: {
        projectId: projectId,
        name: name,
        includeTasks: includeTasks,
      },
    })
      .then((res) => {
        notifier.addAlert('duplicate-success', {
          type: 'success',
          message: 'Prosjektet ble duplisert',
          onClose: () => notifier.hideAlert('duplicate-success'),
          autoHide: true,
        });
        setDuplicate(null);
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert('duplicate-error', {
          type: 'error',
          message: 'Kunne ikke duplisere prosjekt',
          onClose: () => notifier.hideAlert('duplicate-error'),
          autoHide: true,
        });
      });
  };

  const handleDeleteProject = (projectId: string) => {
    deleteProject({ variables: { projectId: projectId } })
      .then(() => {
        notifier.addAlert('duplicate-success', {
          type: 'success',
          message: 'Prosjektet ble permanent slettet',
          onClose: () => notifier.hideAlert('duplicate-success'),
          autoHide: true,
        });
        setDeleteModal(null);
        refreshProjects();
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert('duplicate-error', {
          type: 'error',
          message: 'Kunne ikke slette prosjekt',
          onClose: () => notifier.hideAlert('duplicate-error'),
          autoHide: true,
        });
      });
  };

  return (
    <Box className={classes.root}>
      {projectsLoading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          {allProjects?.length === 0 ? (
            <Box className={classes.noProjectsContainer}>
              <img
                src={PlaceholderImage}
                alt='placeholder'
                className={classes.placeholderImage}
              />
              <Typography my={2}>Du har foreløpig ingen prosjekter</Typography>
              <Button
                endIcon={<Add />}
                onClick={() => navigate(routes.createProject)}
              >
                Legg til prosjekt
              </Button>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Grid container justifyContent='space-between'>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    size='small'
                    freeSolo
                    id='free-solo-2-demo'
                    disableClearable
                    options={
                      allProjects?.map((project: any) => project.name) ?? []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Søk etter prosjekt..'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          startAdornment: <Search />,
                          style: { backgroundColor: '#d8d8d8' },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Typography my={3} variant='h5'>
                Prosjekter
              </Typography>
              <Box className={classes.filterBar}>
                <Button
                  onClick={() => navigate(routes.createProject)}
                  startIcon={<Add />}
                  variant='contained'
                  color='secondary'
                >
                  Nytt prosjekt
                </Button>
                <Box className={classes.filterButtonContainer}></Box>
              </Box>
              <List className={classes.projectList}>
                <ListItem className={classes.listHeader}>
                  <ListItemText>Prosjektnavn</ListItemText>
                  <ListItemText>Startdato</ListItemText>
                  <ListItemText>Sluttdato</ListItemText>
                  <ListItemText sx={{ textAlign: 'center' }}>
                    Status
                  </ListItemText>
                  <ListItemText sx={{ width: 40 }}></ListItemText>
                </ListItem>
                {allProjects?.map((project: any) => {
                  return (
                    <Paper
                      className={classes.listItem}
                      onClick={() =>
                        navigate(`${routes.projects}/${project?.id}`)
                      }
                    >
                      <ListItem>
                        <ListItemText>{project?.name}</ListItemText>
                        <ListItemText>
                          {project?.startDate &&
                            renderDateString(project?.startDate)}
                        </ListItemText>
                        <ListItemText>
                          {project?.endDate &&
                            renderDateString(project?.endDate)}
                        </ListItemText>
                        <ListItemText>
                          <Paper
                            sx={{
                              width: '100%',
                              borderRadius: 5,
                              height: 30,
                              backgroundColor: '#818589',
                              display: 'flex',
                              overflow: 'hidden',
                            }}
                          >
                            {progress?.getProjectsProgress
                              ?.find((x: any) => x.id === project?.id)
                              ?.statusCount?.map((status: any, i: number) => {
                                return (
                                  <Tooltip title={status?.status ?? 'To Do'}>
                                    <Box
                                      sx={{
                                        width: `${status?.percentage ?? 0}%`,
                                        backgroundColor:
                                          getStatus(status?.status)?.color ??
                                          '#818589',
                                        color:
                                          getStatus(status?.status)
                                            ?.textColor ?? 'white',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: 12,
                                      }}
                                    >
                                      {status?.percentage}%
                                    </Box>
                                  </Tooltip>
                                );
                              })}
                          </Paper>
                        </ListItemText>
                        <ListItemText
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Tooltip title='Dupliser prosjekt'>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setDuplicate({
                                  id: project.id,
                                  name: `${project.name} (kopi)`,
                                  includeTasks: true,
                                });
                              }}
                            >
                              <ContentCopy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Dupliser prosjekt'>
                            <IconButton
                              color='error'
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteModal(project.id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </ListItemText>
                      </ListItem>
                    </Paper>
                  );
                })}
              </List>
            </Box>
          )}
        </React.Fragment>
      )}
      <Dialog open={deleteModal ? true : false}>
        <DialogTitle>Slett prosjekt</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Er du sikker på du ønsker å slette dette prosjektet? Denne
            handlingen kan ikke reverseres.
          </DialogContentText>
          <DialogActions>
            <Button color='inherit' onClick={() => setDeleteModal(null)}>
              Avbryt
            </Button>
            <Button
              color='error'
              onClick={() => handleDeleteProject(deleteModal ?? '')}
            >
              Slett
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Modal open={duplicate ? true : false}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width={'100%'}
          height='100%'
        >
          <Paper
            sx={{
              flexDirection: 'column',
              display: 'flex',
              width: 300,
              height: 250,
              p: 2,
              position: 'relative',
            }}
          >
            <IconButton
              sx={{ position: 'absolute', right: 3, top: 3 }}
              onClick={() => setDuplicate(null)}
            >
              <Close />
            </IconButton>
            <Typography variant='h6' textAlign={'center'} mb={2}>
              Dupliser prosjekt
            </Typography>
            <TextField
              label='Nytt navn'
              value={duplicate?.name}
              onChange={(e) =>
                setDuplicate({
                  ...duplicate,
                  name: e.target.value,
                } as Duplicate)
              }
              sx={{ my: 2 }}
              size='small'
              fullWidth
            />
            <FormControlLabel
              label='Inkluder gjøremål'
              control={
                <Checkbox
                  checked={duplicate?.includeTasks}
                  onChange={(e, checked) => {
                    setDuplicate({
                      ...duplicate,
                      includeTasks: checked,
                    } as Duplicate);
                  }}
                />
              }
            />
            <Box
              display='flex'
              flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-end'
            >
              <Button
                variant='contained'
                size='small'
                color='secondary'
                onClick={() =>
                  handleDuplicateProject(
                    duplicate?.id ?? '',
                    duplicate?.name,
                    duplicate?.includeTasks
                  )
                }
                endIcon={
                  duplicateLoading && (
                    <CircularProgress size={16} sx={{ color: 'white' }} />
                  )
                }
              >
                Bekreft
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export const useProjectsPageStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    padding: 20,
    width: '100%',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noProjectsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  placeholderImage: {
    width: 300,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  content: {
    width: '100%',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterButtonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  projectList: {
    width: '100%',
    marginTop: 30,
  },
  listHeader: {
    color: 'GrayText',
    '& .MuiListItemText-root': {
      width: '25%',
    },
  },
  listItem: {
    '& .MuiListItemText-root': {
      width: '25%',
    },
    borderRadius: 5,
    backgroundColor: '#fff',
    marginTop: 15,
    marginBottom: 15,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}05`,
      //transform: 'translateY(-0.25em)',
      cursor: 'pointer',
    },
  },
}));
