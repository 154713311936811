import { useQuery } from '@apollo/client';
import { GET_RECENT_ACTIVITY } from 'graphql/queries/ActivityLog/getRecentActivity';
import { GET_PROJECT } from 'graphql/queries/Projects/getProject';
import { GET_PROJECT_MEMBERS } from 'graphql/queries/Projects/getProjectMembers';
import { PROJECT_SUBSCRIPTION } from 'graphql/subscriptions/ProjectSubscription';
import { RECENT_ACTIVITY_SUBSCRIPTION } from 'graphql/subscriptions/RecentActivitySubscription';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { createGenericContext } from './createGenericContext';
import { useGlobalContext } from './GlobalContext';

interface ProjectContextType {
  loading: boolean;
  currentProject: any;
  projectMembers: any[];
  admin: boolean;
  recentActivity: any[];
}

export const [useProjectContext, BareProjectContextProvider] =
  createGenericContext<ProjectContextType>();

export const ProjectContextProvider: React.FC = ({ children }) => {
  const { id } = useParams();

  const { currentUser, currentTeam } = useGlobalContext();
  const { data, loading, subscribeToMore } = useQuery(GET_PROJECT, {
    variables: {
      id: id,
    },
  });

  /* const { data, loading } = useSubscription(PROJECT_SUBSCRIPTION, {
    variables: { projectId: id },
  }); */

  const { data: teamData } = useQuery(GET_PROJECT_MEMBERS, {
    variables: {
      ids: data?.getProject?.members,
    },
  });

  const { data: recentActivityData, subscribeToMore: subscribeToLog } =
    useQuery(GET_RECENT_ACTIVITY, {
      variables: {
        teamId: currentTeam?.id,
        projectId: id,
        userId: currentUser?.id,
        limit: 8,
      },
    });

  useEffect(() => {
    subscribeToMore({
      document: PROJECT_SUBSCRIPTION,
      variables: { id: id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data?.project) return prev;
        else {
          return Object.assign(
            {},
            {
              getProject: subscriptionData?.data?.project,
            }
          );
        }
      },
    });
    subscribeToLog({
      document: RECENT_ACTIVITY_SUBSCRIPTION,
      variables: {
        teamId: currentTeam?.id,
        projectId: id,
        userId: currentUser?.id,
        limit: 8,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data?.recentActivity) return prev;
        else {
          return Object.assign(
            {},
            {
              getRecentActivity: [
                subscriptionData?.data?.recentActivity,
                ...prev.getRecentActivity.slice(1),
              ],
            }
          );
        }
      },
    });
  }, [currentTeam?.id, currentUser?.id, id, subscribeToLog, subscribeToMore]);

  const value: ProjectContextType = useMemo(
    () => ({
      currentProject: data?.getProject,
      projectMembers: teamData?.getProjectMembers,
      admin: data?.getProject?.projectLeaders?.includes(currentUser?.id),
      loading: loading,
      recentActivity: recentActivityData?.getRecentActivity,
    }),
    [
      currentUser?.id,
      data?.getProject,
      loading,
      recentActivityData?.getRecentActivity,
      teamData?.getProjectMembers,
    ]
  );
  return (
    <BareProjectContextProvider value={value}>
      {children}
    </BareProjectContextProvider>
  );
};
