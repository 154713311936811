import { ApolloError, useMutation } from '@apollo/client';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Stack,
  Modal,
  Paper,
  IconButton,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CREATE_RESET_PASSWORD_REQUEST, LOGIN } from '../../graphql/mutations';
import { CREATE_USER } from '../../graphql/mutations';

import Logo from '../../assets/images/logo-colored.png';
import bg from '../../assets/images/bg-home.jpg';

import { routes } from '../routes';

import { useGlobalContext } from '../../state/context/GlobalContext';
import { useNotificationContext } from '../../state/context/NoticifationContext';
import { useRegisterFormValidation } from './useRegisterFormValidation';

export const LoginRegister: React.FC = () => {
  // REGISTER FORM STATE
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [teamCode, setTeamCode] = useState<string>('');
  const [failedAttempt, setFailedAttempt] = useState<boolean>(false);

  // LOGIN FORM STATE
  const [LoginEmail, setLoginEmail] = useState<string>('');
  const [LoginPassword, setLoginPassword] = useState<string>('');

  // SWITCH VIEWS STATE
  const [SwitchLoginRegister, setSwitchLoginRegister] = React.useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] =
    useState<boolean>(false);
  const [resetLinkEmail, setResetLinkEmail] = useState<string>('');

  const rightPanelActive = 'right-panel-active';
  const { setUser, setSettings, setLoading, setLoggedIn } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const notifier = useNotificationContext();

  const handle = () => {
    if (SwitchLoginRegister) {
      window.history.pushState({}, '', 'login');
      setSwitchLoginRegister(false);
    } else {
      window.history.pushState({}, '', 'signup');
      setSwitchLoginRegister(true);
    }
  };

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      let team = searchParams.get('team');
      if (team) {
        setTeamCode(team);
      }
    }
  }, [location.search]);

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted({ login }) {
      setLoading(true);
      setUser(login);
      setSettings(login?.settings);
      navigate(routes.dashboard, { replace: true });
    },
    onError(error: ApolloError) {
      let title = '';
      let message = '';
      console.error(JSON.stringify(error));
      switch (error.message) {
        case 'User not found': {
          title = 'Ugyldig epost';
          message = 'Fant ingen bruker med denne epost-adressen';
          break;
        }
        case 'Wrong password': {
          title = 'Feil passord';
          message =
            'Passordet som ble oppgitt stemmer ikke overens med brukeren';
          break;
        }
        default: {
          title = 'Noe gikk galt';
          message = 'Kunne ikke logge inn, vennligst prøv igjen';
          break;
        }
      }
      notifier.addAlert(error.message, {
        type: 'error',
        title: title,
        message: message,
        onClose: () => notifier.hideAlert(error.message),
        autoHideDuration: 3000,
      });
    },
  });

  const [createResetRequest, { loading: resetLoading }] = useMutation(
    CREATE_RESET_PASSWORD_REQUEST
  );

  const handleLogin = () => {
    login({
      variables: {
        email: LoginEmail,
        password: LoginPassword,
      },
    });
  };

  const { emailError, passwordError, isValid } = useRegisterFormValidation(
    email,
    password,
    confirmPassword
  );

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted({ createUser }) {
      setUser(createUser);
      setLoggedIn(true);
      navigate(routes.dashboard, { replace: true });
    },
    onError(error) {
      console.error(error);
      notifier.addAlert(error.message, {
        type: 'error',
        title: 'Noe gikk galt',
        message: error.message,
        onClose: () => notifier.hideAlert(error.message),
        autoHideDuration: 3000,
      });
    },
  });

  const handleSignup = () => {
    if (isValid) {
      createUser({
        variables: {
          user: {
            email: email,
            password: password,
            team: teamCode,
            settings: {
              dashboard: {
                widgets: [
                  { id: 'UPCOMING_TASKS', name: 'Kommende gjøremål' },
                  {
                    id: 'TASK_STATS',
                    name: 'Oppgavestatistikk',
                  },
                  {
                    id: 'PROJECTS_PROGRESS',
                    name: 'Progresjon',
                  },
                ],
              },
            },
          },
        },
      });
    } else {
      setFailedAttempt(true);
    }
  };

  const handleResetRequest = () => {
    createResetRequest({
      variables: {
        email: resetLinkEmail,
      },
    })
      .then(() => {
        setForgotPasswordModal(false);
        notifier.addAlert('reset-request', {
          type: 'success',
          message:
            'Instruksjoner for å tilbakestille ditt passord er nå sendt til deg per epost',
          onClose: () => notifier.hideAlert('reset-request'),
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        let message =
          err.message === 'User not found'
            ? 'Fant ingen bruker med denne epost-adressen'
            : 'Kunne ikke sende epost for å tilbakestille passord';
        notifier.addAlert('reset-request-failed', {
          type: 'error',
          title: 'Noe gikk galt',
          message: message,
          onClose: () => notifier.hideAlert('reset-request-failed'),
        });
      });
  };

  React.useEffect(() => {
    if (location.pathname === '/login') {
      setSwitchLoginRegister(false);
    } else {
      setSwitchLoginRegister(true);
    }
  }, [location.pathname]);

  const style = {
    mt: 2,
    ml: 2,
    width: { sm: 200, md: 300 },
    backgroundColor: { sm: '#fff' },
    boxShadow: 6,
    borderRadius: 1,
    margin: 'auto',
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
      }}
    >
      <div
        className={`login__page container ${
          SwitchLoginRegister ? rightPanelActive : ''
        }`}
        id='container'
      >
        <div className='form-container sign-up-container'>
          <Box component='form' style={{ height: '100%' }}>
            <Stack
              sx={{ height: '100%', marginLeft: '20px', marginTop: '20px' }}
            >
              <Typography variant='h1'>Registrer bruker</Typography>
              <div className='login--social__media'>
                {/* <Link to="#">
                    <Facebook />
                  </Link>
                  <Link to="#">
                    <LinkedIn />
                  </Link> */}
              </div>
              <Typography
                variant='body1'
                style={{ color: '#fff', textAlign: 'center' }}
              >
                Fyll ut skjema for registrering:
              </Typography>

              <TextField
                error={failedAttempt && emailError ? true : false}
                helperText={emailError && failedAttempt ? emailError : null}
                id='outlined-basic email'
                placeholder='Email'
                variant='filled'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />

              <TextField
                error={failedAttempt && passwordError ? true : false}
                helperText={
                  passwordError && failedAttempt ? passwordError : null
                }
                placeholder='Passord'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />

              <TextField
                error={passwordError && failedAttempt ? true : false}
                placeholder='Bekreft passord'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />

              <TextField
                placeholder='Team-kode'
                type='text'
                value={teamCode}
                onChange={(e) => setTeamCode(e.target.value)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />
              <Box
                sx={{
                  margin: 'auto',
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleSignup}
                  style={{ marginBottom: 30 }}
                  color='secondary'
                >
                  Sign up
                </Button>
              </Box>
            </Stack>
          </Box>
        </div>
        <div className='form-container sign-in-container'>
          <Box component='form' style={{ height: '100%', width: 'auto' }}>
            <Stack sx={{ height: '100%', marginTop: '10px', width: '100%' }}>
              <img src={Logo} alt='statz' className='logo' />
              {/* <Typography
              className='logo'
              variant='h2'
              color='#fff'
              textAlign={'center'}
            >
              HEV-CRM
            </Typography> */}
              <Typography
                variant='h5'
                sx={{ textAlign: 'center' }}
                className='primaryColor'
              >
                Logg inn
              </Typography>
              <div className='login--social__media'>
                {/* <Link to="#">
                    <Facebook />
                  </Link>
                  <Link to="#">
                    <LinkedIn />
                  </Link> */}
              </div>
              {/* <Typography variant='body1' sx={{ textAlign: 'center' }}>
              Oppgi epost og passord:
            </Typography> */}

              <TextField
                id='outlined-basic email'
                placeholder='Epost'
                variant='outlined'
                value={LoginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />

              <TextField
                id='filled-adornment-password'
                placeholder='Passord'
                variant='outlined'
                type='password'
                value={LoginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                onKeyDown={(e) => (e.key === 'Enter' ? handleLogin() : null)}
                sx={{
                  ...style,
                  '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                      borderColor: '#ebe784',
                    },
                  },
                }}
              />
              <div className='forgot--pass' style={{ textAlign: 'center' }}>
                <Link to='#' onClick={() => setForgotPasswordModal(true)}>
                  Glemt passord?
                </Link>
              </div>
              <Box
                sx={{
                  margin: 'auto',
                }}
              >
                <Button
                  variant='contained'
                  disabled={
                    LoginEmail === ''
                      ? true
                      : LoginPassword === ''
                      ? true
                      : false
                  }
                  onClick={handleLogin}
                  endIcon={loading && <CircularProgress size='small' />}
                  style={{ marginBottom: 30 }}
                  color='secondary'
                >
                  Logg inn
                </Button>
              </Box>
            </Stack>
          </Box>
        </div>
        <div className='overlay-container'>
          <div className='overlay'>
            <div className='overlay-panel overlay-left'>
              <div className='login--header'>
                <img src={Logo} alt='statz' className='logo' />
                {/* <Typography
                className='logo'
                variant='h2'
                color='#fff'
                textAlign={'center'}
              >
                HEV-CRM
              </Typography> */}
                {/*  <Typography variant="h4" color="secondary">
                Registrer bruker
              </Typography> */}
                <div className='login--social__media'>
                  {/* <Link to="#">
                    <Facebook />
                  </Link>
                  <Link to="#">
                    <LinkedIn />
                  </Link> */}
                </div>
                {/* <Typography variant="body1">
                Fyll ut skjema for registrering:
              </Typography> */}
                <Typography variant='body1'>
                  Har du allerede en bruker?
                </Typography>
                <Box
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <Button
                    variant='contained'
                    onClick={handle}
                    color='secondary'
                  >
                    Logg inn
                  </Button>
                </Box>
              </div>
            </div>
            <div className='overlay-panel overlay-right'>
              <Typography variant='h2'>Velkommen!</Typography>
              <Typography variant='body1'>
                Opprett en bruker hos oss og kom raskt i gang!
              </Typography>
              <Button variant='contained' onClick={handle} color='secondary'>
                Registrer deg
              </Button>
            </div>
          </div>
        </div>
        <Modal open={forgotPasswordModal}>
          <Paper className='forgot_password_modal'>
            <IconButton
              className='close-button'
              onClick={() => setForgotPasswordModal(false)}
            >
              <Close />
            </IconButton>
            <Typography variant='h5'>Glemt passord</Typography>

            <Typography variant='h6'>
              Oppgi din epost-adresse, så mottar du instruksjoner for å
              tilbakestille ditt passord.
            </Typography>
            <TextField
              placeholder='
          Epost'
              value={resetLinkEmail}
              onChange={(e) => setResetLinkEmail(e.target.value)}
            />
            <Button
              variant='contained'
              onClick={handleResetRequest}
              endIcon={
                resetLoading && <CircularProgress size={16} color='secondary' />
              }
              color='secondary'
            >
              Bekreft
            </Button>
          </Paper>
        </Modal>
      </div>
    </Box>
  );
};
