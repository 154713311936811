import { gql } from '@apollo/client';

export const SET_TASK_DEADLINE = gql`
  mutation SetTaskDeadline(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $deadline: Date
  ) {
    setTaskDeadline(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      deadline: $deadline
    ) {
      name
    }
  }
`;
