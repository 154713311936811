import { Breadcrumbs, Link as MUILink } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

type Route = {
  label: string;
  path: string;
  icon?: ReactElement;
};

interface BreadcrumbsNavigationProps {
  routes: Route[];
}

export const BreadcrumbesNavigation: React.FC<BreadcrumbsNavigationProps> = ({
  routes,
}) => {
  const location = useLocation();
  return (
    <Breadcrumbs>
      {routes.map((route) => {
        return (
          <MUILink
            color={location.pathname === route.path ? 'primary' : 'inherit'}
            fontWeight='bold'
            underline='hover'
            component={Link}
            to={route.path}
          >
            {route.icon}
            {route.label}
          </MUILink>
        );
      })}
    </Breadcrumbs>
  );
};
