import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { environment } from '../utils/environment';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

const serverUrl = {
  production: 'https://crm.statz.no/graphql',
  development: 'http://localhost:8080/graphql',
  test: 'http://localhost:8080/graphql',
};

const serverUrlWs = {
  production: 'wss://crm.statz.no/graphql',
  development: 'ws://localhost:8080/graphql',
  test: 'ws://localhost:8080/graphql',
};

const httpLink = createUploadLink({
  uri: serverUrl[environment],
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: serverUrlWs[environment],
  options: {
    timeout: 60000,
    reconnect: true,
    // connectionParams: {
    //     authToken: user.authToken,
    //   },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  uri: serverUrl[environment],
  credentials: 'include',
  link: splitLink,
  cache: new InMemoryCache(),
});
