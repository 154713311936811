import { gql } from '@apollo/client';

export const EDIT_MONDAY_SETTINGS = gql`
  mutation EditMondaySettings($userId: ID, $settings: MondaySettingsInput) {
    editMondaySettings(userId: $userId, settings: $settings) {
      dashboard {
        monday {
          userId
          name
          defaultBoard
          defaultWorkspace
        }
      }
    }
  }
`;
