import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($email: String) {
    getUser(email: $email) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      admin
      color
      settings {
        dashboard {
          widgets {
            id
            name
          }
        }
      }
    }
  }
`;
