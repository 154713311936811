import { createTheme, CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const ThemeProvider: React.FC = ({ children }) => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          secondary: {
            main: '#ffe784',
            contrastText: '#181819',
          },
          primary: {
            main: '#181819',
            contrastText: '#fff',
          },
        },
        components: {
          MuiCheckbox: {
            styleOverrides: {
              colorPrimary: {
                '&$checked': {
                  color: '#181819',
                  borderColor: '#181819',
                },
              },
            },
          },
        },
      }),
    []
  );
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
