import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($user: UserInput) {
    createUser(data: $user) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      username
      avatar
      admin
      color
      teams
      settings {
        dashboard {
          widgets {
            id
            name
          }
        }
      }
    }
  }
`;
