import { gql } from '@apollo/client';

export const EDIT_USER = gql`
  mutation EditUser($id: ID, $user: UserInput) {
    editUser(id: $id, user: $user) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      admin
      color
      settings {
        dashboard {
          widgets {
            id
            name
          }
        }
      }
    }
  }
`;
