import { TextField, TextFieldProps, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

export const CustomDateInput: React.FC<TextFieldProps> = (props) => {
  const classes = useCustomDateStyles();
  return (
    <TextField
      sx={{ borderStyle: 'none' }}
      className={classes.root}
      {...props}
    />
  );
};

const useCustomDateStyles = makeStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #ced4da',
        borderRadius: 4,
      },
    },
    '& .MuiTextField-root': {
      position: 'relative',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));
