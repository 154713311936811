import { gql } from '@apollo/client';

export const SET_COMPETITION_STATUS = gql`
  mutation SetCompetitionStatus($teamId: ID, $compId: ID, $status: String) {
    setCompetitionStatus(teamId: $teamId, compId: $compId, status: $status) {
      id
      name
      startDate
      endDate
      measurement
      prizes {
        position
        description
      }
      unit {
        id
        name
        suffix
      }
      goal
      status
    }
  }
`;
