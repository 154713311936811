import { gql } from '@apollo/client';

export const AUTHENTICATE = gql`
  query AuthenticateUser {
    authenticateUser {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      admin
      color
      settings {
        dashboard {
          widgets {
            id
            name
          }
        }
      }
    }
  }
`;
