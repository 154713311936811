import { useMutation } from '@apollo/client';
import { Add, Close } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';
import { SET_TASK_DEADLINE } from 'graphql/mutations/Projects/setTaskDeadline';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { Task } from 'types/task';
import { renderDateString } from 'utils/DateHandler';

interface DeadlineCellProps {
  parentId?: string | null | undefined;
  taskId: string;
  deadline: string | null | undefined;
}

export const DeadlineCell: React.FC<DeadlineCellProps> = ({
  parentId,
  taskId,
  deadline,
}) => {
  const [inFocus, setInFocus] = useState<boolean>(false);

  const { currentUser } = useGlobalContext();
  const { currentProject } = useProjectContext();
  const notifier = useNotificationContext();

  const [changeDeadline] = useMutation(SET_TASK_DEADLINE);
  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const classes = useDeadlineStyles();

  const handleDeadlineSelected = (date: Date | null) => {
    changeDeadline({
      variables: {
        projectId: currentProject?.id,
        parentId: parentId,
        taskId: taskId,
        deadline: date,
      },
    })
      .then(() => {
        log({
          variables: {
            data: {
              type: 'task-deadline',
              madeBy: currentUser?.id,
              teamId: currentProject?.teamId,
              projectId: currentProject?.id,
              description: `Endret deadline på oppgave til ${renderDateString(
                date?.toISOString()
              )}`,
            },
          },
        });
        let taskData = !parentId
          ? currentProject?.tasks?.find((task: Task) => task._id === taskId)
          : currentProject?.tasks
              ?.find((task: Task) => task?._id === parentId)
              ?.subTask?.find((subTask: Task) => subTask?._id === taskId);
        if (taskData.owner && taskData.owner !== currentUser?.id) {
          addNotification({
            variables: {
              data: {
                type: `task-dealine`,
                sender: currentUser?.id,
                receivers: [taskData.owner],
                message: `endret deadline på en av dine oppgaver til ${renderDateString(
                  date?.toISOString()
                )}`,
                subject: currentProject?.id,
              },
            },
          });
        }
      })
      .catch(() => {
        notifier.addAlert('deadline-change-error', {
          type: 'error',
          message: 'Kunne ikke endre deadline, vennligs prøv igjen',
          onClose: () => notifier.hideAlert('deadline-change-error'),
          autoHide: true,
        });
      });
  };

  return (
    <Box
      onMouseOver={() => setInFocus(true)}
      onMouseOut={() => setInFocus(false)}
      onClick={!deadline ? () => handleDeadlineSelected(new Date()) : undefined}
      sx={{
        '&:hover': { cursor: 'pointer' },
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {deadline ? (
          <Grid container justifyContent='space-evenly'>
            <Grid item xs={inFocus ? 10 : 12}>
              <DesktopDatePicker
                inputFormat='dd/MM/yyyy'
                value={new Date(deadline)}
                onChange={(date) => handleDeadlineSelected(date)}
                InputProps={{ style: { fontSize: 12 } }}
                renderInput={(params) => (
                  <TextField
                    size='small'
                    inputProps={{ style: { fontSize: 40 } }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={inFocus ? 2 : 0}
              onClick={() => handleDeadlineSelected(null)}
            >
              {inFocus && (
                <IconButton sx={{ color: 'inherit' }} size='small' disabled>
                  <Close className={classes.editIcon} fontSize='small' />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ) : inFocus ? (
          <IconButton disabled size='small'>
            <Add className={classes.editIcon} fontSize='small' />
          </IconButton>
        ) : null}
      </LocalizationProvider>
    </Box>
  );
};

const useDeadlineStyles = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.primary.main,
  },
}));
