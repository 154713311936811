import { gql } from '@apollo/client';

export const TEAM_RECENT_ACTIVITY = gql`
  subscription TeamRecentActivity($teamId: ID, $limit: Int) {
    teamRecentActivity(teamId: $teamId, limit: $limit) {
      type
      madeBy
      timestamp
      description
      projectId
    }
  }
`;
