import { Workspaces } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BreadcrumbesNavigation } from 'components/BreadcrumbsNavigation/BreadcrumbsNavigation';
import { routes } from 'pages/routes';
import React, { useState } from 'react';
import { useProjectContext } from 'state/context/ProjectContext';
import { Details } from './TabComponents/Details';
import { Hours } from './TabComponents/Hours/Hours';
import { Kanban } from './TabComponents/Kanban/Kanban';
import { Tasks } from './TabComponents/Tasks/Tasks';

export const Project: React.FC = () => {
  const classes = useProjectStyles();
  const [currentTab, setCurrentTab] = useState<string>('Gjøremål');

  const { currentProject, loading } = useProjectContext();

  return (
    <Box className={classes.root}>
      {!loading ? (
        <React.Fragment>
          <BreadcrumbesNavigation
            routes={[
              {
                label: 'Prosjekter',
                path: routes.projects,
                icon: <Workspaces fontSize='inherit' sx={{ mr: 0.5 }} />,
              },
              {
                label: currentProject?.name,
                path: `${routes.projects}/${currentProject?.id}`,
              },
            ]}
          />
          <Typography variant='h4' className={classes.title}>
            {currentProject?.name}
          </Typography>
          <TabContext value={currentTab}>
            <TabList onChange={(e, value) => setCurrentTab(value)}>
              <Tab label='Detaljer' value={'Detaljer'} />
              <Tab label='Gjøremål' value={'Gjøremål'} />
              <Tab label='Kanban' value={'Kanban'} />
              <Tab label='Timeliste' value={'Timeliste'} />
            </TabList>
            <TabPanel style={{ flex: 1 }} value={'Detaljer'}>
              <Details />
            </TabPanel>
            <TabPanel value={'Gjøremål'} sx={{ height: 'auto' }}>
              <Tasks />
            </TabPanel>
            <TabPanel sx={{ maxHeight: '80%' }} value={'Kanban'}>
              <Kanban />
            </TabPanel>
            <TabPanel value={'Timeliste'}>
              <Hours />
            </TabPanel>
          </TabContext>
        </React.Fragment>
      ) : (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const useProjectStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    display: 'flex',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  title: {
    marginTop: 40,
    marginBottom: 20,
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
