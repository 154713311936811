import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WidgetGrid } from 'components/layout/WidgetGrid/WidgetGrid';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';

export const DashboardPage: React.FC = () => {
  const classes = useDashboardStyles();
  const { currentUser } = useGlobalContext();

  return (
    <Box className={classes.root}>
      <Box
        display={'flex'}
        justifyContent='space-between'
        alignItems='center'
        ml={2.5}
        mr={2.5}
      >
        <Typography variant='h1' className={classes.welcomeText}>
          Velkommen tilbake,{' '}
          <Typography
            variant='h1'
            display='inline'
            className={classes.nameText}
          >
            {currentUser?.firstName}
          </Typography>
        </Typography>
      </Box>

      <WidgetGrid />
    </Box>
  );
};

const useDashboardStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: 20,
    width: '100%',
  },
  projectPicker: {
    margin: 20,
    minWidth: 200,
    fontSize: 30,
    color: theme.palette.secondary.main,

    '& .MuiSelect-icon': {
      color: theme.palette.secondary.main,
      fontSize: 30,
      transform: 'translateX(50%)',
    },
  },
  welcomeText: {
    fontSize: 30,
    color: 'grayText',
  },
  nameText: {
    fontSize: 30,
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
}));
