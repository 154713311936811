import { User } from 'types/user';

export const displayName = (user: User | null | undefined) => {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user?.email) {
    return user.email;
  } else {
    return 'Unknown';
  }
};

export const capitalizeFirst = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const nl2br = (string: string, is_xhtml?: boolean) => {
  if (typeof string === 'undefined' || string === null) {
    return '';
  }
  var breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return (string + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
};
