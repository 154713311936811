import { gql } from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription Notifications($userId: ID, $teamId: ID, $limit: Int) {
    notifications(userId: $userId, teamId: $teamId, limit: $limit) {
      id
      message
      type
      readBy
      sender
      timestamp
      subject
    }
  }
`;
