import { gql } from '@apollo/client';

export const CREATE_SUBTASK = gql`
  mutation CreateSubTask($projectId: ID, $taskId: ID, $data: SubTaskInput) {
    createSubTask(projectId: $projectId, taskId: $taskId, data: $data) {
      _id
      name
      status
      description
      deadline
      priority
      owner
      hours
      hoursEstimated
      timestamp
    }
  }
`;
