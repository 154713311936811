import { gql } from '@apollo/client';

export const TEAMS = gql`
  query Teams($ids: [ID]) {
    teams(ids: $ids) {
      id
      name
      settings {
        priorityLabels {
          name
          color
          textColor
        }
        statuses {
          name
          color
          textColor
        }
      }
      members
    }
  }
`;
