import { gql } from '@apollo/client';

export const EDIT_SETTINGS = gql`
  mutation EditSettings($settings: InputSettings, $teamId: ID) {
    editSettings(settings: $settings, teamId: $teamId) {
      meme {
        aktiv
      }
    }
  }
`;
