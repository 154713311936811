import { Box, Typography } from '@mui/material';
import React, { MouseEvent } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';

interface PriorityCellProps {
  priority: string | null | undefined;
  onClick?: (event: MouseEvent<HTMLTableCellElement>) => void;
  className?: string | undefined;
}

export const PriorityCell: React.FC<PriorityCellProps> = ({
  priority,
  onClick,
  className,
}) => {
  const { currentTeam } = useGlobalContext();

  const priorityData = currentTeam?.settings?.priorityLabels?.find(
    (data) => data.name === priority
  );

  return (
    <Box
      className={className}
      sx={{
        bgcolor: priorityData?.color ?? 'inherit',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderLeftColor: '#ced4da',
        borderLeftStyle: 'solid',
        borderWidth: 0.1,
      }}
      onClick={onClick}
    >
      <Typography
        sx={{ fontSize: 12 }}
        color={priorityData?.textColor ?? 'white'}
      >
        {priorityData?.name ?? ''}
      </Typography>
    </Box>
  );
};
