import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      admin
      color
      settings {
        dashboard {
          widgets {
            id
            name
          }
        }
      }
    }
  }
`;
