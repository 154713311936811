import { gql } from '@apollo/client';

export const PROJECT_SUBSCRIPTION = gql`
  subscription ProjectSubscription($id: ID) {
    project(id: $id) {
      id
      name
      mondayWorkspace
      mondayBoard
      teamId
      powerofficeProject
      description
      startDate
      endDate
      members
      createdByName
      createdById
      createdAt
      projectLeaders
      hoursBudget
      archived
      tasks {
        _id
        name
        description
        status
        priority
        deadline
        owner
        hours
        hoursEstimated
        timestamp
        subTask {
          _id
          name
          description
          status
          priority
          deadline
          owner
          hours
          hoursEstimated
          timestamp
        }
      }
    }
  }
`;
