import { gql } from '@apollo/client';

export const SET_TASK_STATUS = gql`
  mutation SetTaskStatus(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $status: String
  ) {
    setTaskStatus(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      status: $status
    ) {
      name
    }
  }
`;
