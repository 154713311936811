/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useSubscription } from '@apollo/client';
import { PriorityHighRounded, Task as TaskIcon } from '@mui/icons-material';
import { Box, Pagination, Paper, Tooltip, Typography } from '@mui/material';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import { GET_MY_TASKS } from 'graphql/queries/Projects/getMyTasks';
import { TEAM_RECENT_ACTIVITY } from 'graphql/subscriptions/TeamRecentActivity';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { Task } from 'types/task';
import { renderDateString } from 'utils/DateHandler';

interface UpcomingTasksProps {}

export const UpcomingTasks: React.FC<UpcomingTasksProps> = () => {
  const { currentUser, currentTeam } = useGlobalContext();
  const [page, setPage] = useState<number>(1);

  const { data, refetch } = useQuery(GET_MY_TASKS, {
    variables: { userId: currentUser?.id, teamId: currentTeam?.id },
    onError(error) {
      console.error(error);
    },
  });

  const { data: activityData } = useSubscription(TEAM_RECENT_ACTIVITY, {
    variables: {
      teamId: currentTeam?.id,
      limit: 10,
    },
  });

  const myTasks = data?.getMyTasks;

  const getStatus = (task: Task) =>
    currentTeam?.settings?.statuses?.find((x) => x.name === task?.status);

  const getPriority = (task: Task) =>
    currentTeam?.settings?.priorityLabels?.find(
      (x) => x.name === task?.priority
    );

  useEffect(() => {
    refetch();
  }, [activityData]);

  return (
    <Widget
      width={4}
      height={'medium'}
      title='Dine oppgaver'
      subtitle='Alle prosjekter'
      icon={<TaskIcon />}
    >
      <Box
        flex={1}
        display='flex'
        justifyContent='space-between'
        flexDirection={'column'}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          flex={1}
        >
          {myTasks?.length > 0 ? (
            myTasks
              ?.slice((page - 1) * 5, (page - 1) * 5 + 5)
              ?.map((task: Task) => {
                return (
                  <Paper
                    sx={{
                      borderRadius: 2,
                      backgroundColor: getStatus(task)?.color ?? '#818589',
                      color: getStatus(task)?.textColor ?? 'white',
                      height: 35,
                      margin: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                    }}
                  >
                    <Tooltip title={task.name}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          width: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {task?.name}
                      </Typography>
                    </Tooltip>
                    <Typography sx={{ fontSize: 12 }}>
                      {renderDateString(task?.deadline)}
                    </Typography>
                    {task?.priority && (
                      <Tooltip title={`${task?.priority} prioritet`}>
                        <PriorityHighRounded
                          sx={{
                            backgroundColor: getPriority(task)?.color,
                            borderRadius: '50%',
                            fontSize: 20,
                            borderColor: 'white',
                            borderStyle: 'solid',
                            borderWidth: 0.1,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Paper>
                );
              })
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' sx={{ color: 'GrayText' }}>
                Foreløpig ingen oppgaver
              </Typography>
            </Box>
          )}
        </Box>
        {data?.getMyTasks?.length > 5 && (
          <Pagination
            sx={{ alignSelf: 'center' }}
            count={Math.ceil(data?.getMyTasks?.length / 5)}
            onChange={(e, val) => setPage(val)}
          />
        )}
      </Box>
    </Widget>
  );
};
