import { useMutation } from '@apollo/client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import { SET_PO_USER } from 'graphql/mutations/Poweroffice/setPOuser';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';

export const HourSummary: React.FC = () => {
  const classes = useHourSummaryStyles();
  const [currentTab, setCurrentTab] = useState<string>('SUMMARY');
  const [email, setEmail] = useState('');
  const { currentUser } = useGlobalContext();
  const notifier = useNotificationContext();

  const [setPOUser] = useMutation(SET_PO_USER);
  const handleSetUser = () => {
    setPOUser({ variables: { email: email } })
      .then((success) => {
        if (!success) {
          notifier.addAlert('INVALID_PO_EMAIL', {
            type: 'error',
            message: 'Fant ingen Poweroffice-bruker med denne epostadressen',
            onClose: () => notifier.hideAlert('INVALID_PO_EMAIL'),
            autoHide: true,
          });
        }
      })
      .catch(() => {
        notifier.addAlert('PO_EMAIL_ERROR', {
          type: 'error',
          message: 'Noe gikk galt, vennligst prøv igjen senere.',
          onClose: () => notifier.hideAlert('PO_EMAIL_ERROR'),
          autoHide: true,
        });
      });
  };

  return (
    <Widget height='medium'>
      <Box className={classes.logoContainer}>
        <img
          className={classes.logo}
          src='https://directa.no/wp-content/uploads/2020/12/poweroffice-go.png'
          alt='logo'
        />
      </Box>
      <Box className={classes.root}>
        {currentUser?.settings?.dashboard?.poweroffice?.employeeId ? (
          <TabContext value={currentTab}>
            <TabList centered onChange={(e, value) => setCurrentTab(value)}>
              <Tab label='Oppsummering' value={'SUMMARY'} />
              <Tab label='Prosjekter' value={'PROJECTS'} />
            </TabList>
            <TabPanel
              sx={{
                width: '100%',
                paddingLeft: 0,
                paddingRight: 0,
              }}
              value='SUMMARY'
            >
              <Box className={classes.hourDetailsContainer}>
                <Box className={classes.billableContainer}>
                  <Typography className={classes.billableLabel}>
                    Fakturerbart
                  </Typography>
                  <Typography>28t 30m</Typography>
                </Box>
                <Box className={classes.totalHoursContainer}>
                  <Typography className={classes.totalHoursLabel}>
                    Totalt timer
                  </Typography>
                  <Typography className={classes.totalHours}>
                    37t 30m
                  </Typography>
                </Box>
                <Box className={classes.billableContainer}>
                  <Typography className={classes.billableLabel}>
                    Ikke-fakturerbart
                  </Typography>
                  <Typography>9t 30m</Typography>
                </Box>
              </Box>
              <LinearProgress
                variant='determinate'
                value={75}
                valueBuffer={20}
              />
              <List>
                <ListItem>
                  <ListItemText sx={{ display: 'flex' }}>
                    <Box display={'flex'} alignItems='center'>
                      <Typography className={classes.projectLabel}>
                        HEV-CRM
                      </Typography>
                      <Typography className={classes.customerLabel}>
                        Helgeland Event
                      </Typography>
                    </Box>
                  </ListItemText>
                  <ListItemIcon>
                    <Typography>16t 30m</Typography>
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </List>
            </TabPanel>
          </TabContext>
        ) : (
          <Box className={classes.formContainer}>
            <Typography className={classes.instructions}>
              Oppgi epost-adressen tilknyttet din brukerkonto i PowerOffice Go
            </Typography>
            <TextField
              type={'email'}
              label='Epost'
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              sx={{ marginTop: 2 }}
              variant='contained'
              onClick={handleSetUser}
            >
              Fortsett
            </Button>
          </Box>
        )}
      </Box>
    </Widget>
  );
};

const useHourSummaryStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .MuiTab-root': {
      fontSize: 12,
      textTransform: 'none',
      paddingBottom: 0,
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 150,
    objectFit: 'contain',
    marginBottom: 0,
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  instructions: {
    color: 'graytext',
    marginBottom: 8,
  },
  totalHoursContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '40%',
  },
  totalHoursLabel: {
    fontSize: 14,
    color: 'graytext',
  },
  totalHours: {
    fontSize: 24,
  },
  hourDetailsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: 5,
  },
  billableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
  },
  billableLabel: {
    fontSize: 12,
    color: 'graytext',
  },
  projectLabel: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  customerLabel: {
    color: 'graytext',
    fontSize: 12,
    fontStyle: 'italic',
  },
}));
