import { gql } from '@apollo/client';

export const SET_TASK_NAME = gql`
  mutation SetTaskName(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $name: String
  ) {
    setTaskName(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      name: $name
    ) {
      name
    }
  }
`;
