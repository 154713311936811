import { gql } from '@apollo/client';

export const SET_TASK_HOURS = gql`
  mutation SetTaskHours(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $hours: Float
  ) {
    setTaskHours(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      hours: $hours
    ) {
      name
    }
  }
`;
