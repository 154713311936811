import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $path: String, $filename: String) {
    uploadFile(file: $file, path: $path, filename: $filename) {
      filename
      mimetype
      encoding
    }
  }
`;
