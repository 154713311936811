import { useMutation } from '@apollo/client';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';
import { SET_TASK_OWNER } from 'graphql/mutations/Projects/setTaskOwner';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { displayName } from 'utils/Stringparser';

interface OwnerPopperProps {
  parentId?: string | null | undefined;
  taskId: string;
  onClose: () => void;
}

export const OwnerPopper: React.FC<OwnerPopperProps> = ({
  parentId,
  taskId,
  onClose,
}) => {
  const { currentUser } = useGlobalContext();
  const { currentProject, projectMembers } = useProjectContext();
  const notifier = useNotificationContext();

  const [changeOwner] = useMutation(SET_TASK_OWNER);
  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const handleOwnerSelected = (owner: string | null) => {
    changeOwner({
      variables: {
        projectId: currentProject?.id,
        parentId: parentId,
        taskId: taskId,
        owner: owner,
      },
    })
      .then(() => {
        log({
          variables: {
            data: {
              type: `task-owner`,
              madeBy: currentUser?.id,
              teamId: currentProject?.teamId,
              projectId: currentProject?.id,
              description: `Endret ansvarlig på en oppgave`,
            },
          },
        });
        if (owner) {
          addNotification({
            variables: {
              data: {
                type: 'task-owner',
                sender: currentUser?.id,
                receivers: [owner],
                message: 'tildelte deg en oppgave',
                subject: currentProject?.name,
              },
            },
          });
        }
      })
      .catch(() => {
        notifier.addAlert('status-change-error', {
          type: 'error',
          message: 'Kunne ikke endre status, vennligs prøv igjen',
          onClose: () => notifier.hideAlert('status-change-error'),
          autoHide: true,
        });
      })
      .finally(() => onClose());
  };

  return (
    <List
      sx={{ maxWidth: 300, maxHeight: 250, overflow: 'auto', px: 2 }}
      subheader={<ListSubheader component='div'>Teammedlemmer</ListSubheader>}
    >
      <ListItemButton onClick={() => handleOwnerSelected(null)}>
        <ListItemText>Ingen</ListItemText>
      </ListItemButton>
      {projectMembers?.map((member) => (
        <ListItemButton onClick={() => handleOwnerSelected(member?.id)}>
          <ListItemIcon>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: member?.color ?? undefined,
              }}
              src={member?.avatar ?? undefined}
            />
          </ListItemIcon>
          <ListItemText>{displayName(member)}</ListItemText>
        </ListItemButton>
      ))}
    </List>
  );
};
