import { gql } from '@apollo/client';

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: ID, $userId: ID) {
    readNotification(id: $id, userId: $userId) {
      id
      message
      type
      timestamp
      sender
      readBy
      subject
    }
  }
`;
