import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Typography } from '@mui/material';
import { GET_PROJECT_HOURS } from 'graphql/queries/Projects/getProjectHours';
import React from 'react';
import { useProjectContext } from 'state/context/ProjectContext';

export const Hours: React.FC = () => {
  const { currentProject } = useProjectContext();
  const { data: hoursData } = useQuery(GET_PROJECT_HOURS, {
    variables: { projectId: currentProject?.id },
  });
  const percentage =
    (hoursData?.getProjectHours?.current / currentProject?.hoursBudget) * 100;

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          m={2}
        >
          <Typography variant='h6'>Totalt timer brukt</Typography>
          <Typography>{hoursData?.getProjectHours?.current}</Typography>
        </Box>
        {currentProject?.hoursBudget && currentProject?.hoursBudget > 0 ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            m={2}
          >
            <Typography variant='h6'>Timebudsjett</Typography>
            <Typography>{currentProject?.hoursBudget}</Typography>
          </Box>
        ) : null}
      </Box>
      {currentProject?.hoursBudget && currentProject?.hoursBudget > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 250, position: 'relative' }}>
            <Typography
              sx={{ position: 'absolute', right: -40 }}
            >{`${Math.round(percentage)}%`}</Typography>
            <LinearProgress
              variant='determinate'
              value={percentage}
              sx={{ height: 20, borderRadius: 2 }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
