import { gql } from '@apollo/client';

export const SETTINGS_SUBSCRIPTION = gql`
  subscription settings($userId: ID) {
    userSettings(userId: $userId) {
      userId
      data {
        background {
          images
        }
        priorityLabels {
          name
          color
          textColor
        }
      }
    }
  }
`;
