import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface PageHeadingProps {
  icon: React.ReactNode;
}

export const FancyIcon: React.FC<PageHeadingProps> = ({ icon }) => {
  const classes = useStyles();

  return (
    <Paper elevation={9} className={classes.paper}>
      {icon}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
}));
