import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DRAWER_WIDTH } from 'components/layout/Drawer/Drawer';
import Logo from 'assets/images/monday-logo.png';
import React, { useState } from 'react';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { LOGIN_MONDAY } from 'graphql/mutations/Monday/loginMonday';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from 'state/context/GlobalContext';

interface AuthenticateMondayProps {
  open: boolean;
  onClose: () => void;
}

export const AuthenticateMonday: React.FC<AuthenticateMondayProps> = ({
  open,
}) => {
  const classes = useAuthenticateMondayStyles();
  const [token, setToken] = useState<string>();
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);

  const notifier = useNotificationContext();
  const { currentUser } = useGlobalContext();
  const [loginMonday] = useMutation(LOGIN_MONDAY);

  const handleLogin = () => {
    setAttemptingLogin(true);
    loginMonday({
      variables: {
        userId: currentUser?.id,
        token: token,
      },
    })
      .then((res) => {
        let success = res?.data?.loginMonday;
        if (success) {
          notifier.addAlert('monday_success', {
            type: 'success',
            message: 'Autentisering velykket',
            onClose: () => notifier.hideAlert('monday_success'),
          });
        } else {
          notifier.addAlert('monday_fail', {
            type: 'error',
            title: 'Noe gikk galt',
            message: 'API-nøkkelen er ikke gyldig',
            onClose: () => notifier.hideAlert('monday_fail'),
          });
        }
      })
      .catch((err) => {
        notifier.addAlert('monday_fail', {
          type: 'error',
          title: 'Noe gikk galt',
          message: err?.message,
          onClose: () => notifier.hideAlert('monday_fail'),
        });
      })
      .finally(() => {
        setAttemptingLogin(false);
      });
  };
  return (
    <Modal open={open}>
      <Box className={classes.root}>
        <Card className={classes.cardContainer}>
          <img src={Logo} alt='monday' className={classes.logoContainer} />
          <Box className={classes.instructions}>
            <Typography variant='h6'>Få tilgang til Monday</Typography>
            <Typography>
              1. Logg inn med din bruker på{' '}
              <a href='https://monday.com'>Monday.com</a>
            </Typography>
            <Typography>
              2. Klikk på din avatar nede i venstre hjørne
            </Typography>
            <Typography>3. Velg "Developers" fra menyen</Typography>
            <Typography>4. Klikk på nedtrekksmenyen "Developer"</Typography>
            <Typography>5. Velg deretter "My Access Tokens"</Typography>
            <Typography>6. Kopier din API-nøkkel</Typography>
          </Box>
          <Box className={classes.form}>
            <TextField
              variant='outlined'
              placeholder='API-nøkkel...'
              onChange={(e) => setToken(e.target.value)}
            />
            {!attemptingLogin ? (
              <Button onClick={handleLogin}>Logg inn</Button>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

const useAuthenticateMondayStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    marginLeft: DRAWER_WIDTH,
    width: 375,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
  },
  logoContainer: {
    width: '50%',
    objectFit: 'contain',
    marginBottom: 20,
  },
  form: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  instructions: {
    marginBottom: 20,
  },
}));
