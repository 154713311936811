const shortMonthNames = [
  'jan',
  'feb',
  'mars',
  'apr',
  'mai',
  'juni',
  'juli',
  'aug',
  'sept',
  'okt',
  'nov',
  'des',
];

export const renderDateString = (date: string | null | undefined) => {
  if (date) {
    let timestamp = new Date(date);
    return timestamp?.toLocaleDateString();
  } else {
    return '';
  }
};

export const renderDateTimeString = (date: string | null | undefined) => {
  if (date) {
    let timestamp = new Date(date);
    return timestamp?.toLocaleTimeString();
  } else {
    return '';
  }
};

export const renderPrettyDateTimeString = (date: string | null | undefined) => {
  if (date) {
    let timestamp = new Date(date);
    let day = timestamp?.getDate() + 1;
    return `${day < 10 ? 0 : ''}${day} ${
      shortMonthNames[timestamp?.getMonth()]
    } ${timestamp?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  } else {
    return '';
  }
};
