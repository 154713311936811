import { gql } from '@apollo/client';

export const GET_TODOS = gql`
  query GetTodos($userId: ID, $boardId: Float) {
    getTodos(userId: $userId, boardId: $boardId) {
      statuses {
        label
        color
        border
      }
      items {
        id
        name
        person
        status
        deadline
      }
    }
  }
`;
