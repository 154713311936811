import { gql } from '@apollo/client';

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects($teamId: ID, $userId: ID) {
    getAllProjects(teamId: $teamId, userId: $userId) {
      id
      name
      startDate
      endDate
      createdByName
      createdById
      createdAt
      hoursBudget
      tasks {
        _id
        name
        description
        status
        priority
        deadline
        owner
        hours
        hoursEstimated
        timestamp
      }
    }
  }
`;

export const GET_ALL_PROJECTS_SIMPLE = gql`
  query GetAllProjectsSimple($teamId: ID) {
    getAllProjects(teamId: $teamId) {
      id
      name
    }
  }
`;
