import { useMutation } from '@apollo/client';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';
import { ADD_NOTIFICATION } from 'graphql/mutations/Notification/addNotification';
import { SET_TASK_PRIORITY } from 'graphql/mutations/Projects/setTaskPriority';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useProjectContext } from 'state/context/ProjectContext';
import { Task } from 'types/task';

interface PriorityPopperProps {
  parentId?: string | undefined | null;
  taskId: string;
  onClose: () => void;
}

export const PriorityPopper: React.FC<PriorityPopperProps> = ({
  parentId,
  taskId,
  onClose,
}) => {
  const { currentTeam, currentUser } = useGlobalContext();
  const { currentProject } = useProjectContext();
  const notifier = useNotificationContext();

  const [changePriority] = useMutation(SET_TASK_PRIORITY);
  const [log] = useMutation(ADD_TO_LOG);
  const [addNotification] = useMutation(ADD_NOTIFICATION);

  const handlePriorityClicked = (priority: string | null) => {
    changePriority({
      variables: {
        projectId: currentProject?.id,
        parentId: parentId,
        taskId: taskId,
        priority: priority,
      },
    })
      .then(() => {
        log({
          variables: {
            data: {
              type: `task-priority:${priority}`,
              madeBy: currentUser?.id,
              teamId: currentTeam?.id,
              projectId: currentProject?.id,
              description: `Endret prioritet på oppgave til "${priority}"`,
            },
          },
        });
        let taskData = !parentId
          ? currentProject?.tasks?.find((task: Task) => task._id === taskId)
          : currentProject?.tasks
              ?.find((task: Task) => task?._id === parentId)
              ?.subTask?.find((subTask: Task) => subTask?._id === taskId);
        if (taskData?.owner && taskData.owner !== currentProject?.id) {
          addNotification({
            variables: {
              data: {
                type: `task-priority:${priority}`,
                sender: currentUser?.id,
                receivers: [taskData.owner],
                message: `endret prioritet på en av dine opggaver til "${priority}"`,
                subject: currentProject?.name,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert('priority-change-error', {
          type: 'error',
          message: 'Kunne ikke endre prioritet, vennligs prøv igjen',
          onClose: () => notifier.hideAlert('priority-change-error'),
          autoHide: true,
        });
      })
      .finally(() => onClose());
  };

  return (
    <Grid
      component={Paper}
      container
      justifyContent={'space-between'}
      spacing={1}
      sx={{ maxWidth: 300, p: 2 }}
    >
      {currentTeam?.settings?.priorityLabels?.map((priority) => (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
          }}
          item
          xs={6}
        >
          <Button
            sx={{
              backgroundColor: priority.color,
              '&:hover': { opacity: 0.7, backgroundColor: priority.color },
              height: 40,
              width: '100%',
            }}
            onClick={() => handlePriorityClicked(priority.name)}
          >
            <Typography sx={{ fontSize: 12 }} color={priority.textColor}>
              {priority.name}
            </Typography>
          </Button>
        </Grid>
      ))}
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
        }}
        item
        xs={6}
      >
        <Button
          sx={{
            backgroundColor: '#818589',
            '&:hover': { opacity: 0.7, backgroundColor: '#818589' },
            height: 40,
            width: '100%',
          }}
          onClick={() => handlePriorityClicked(null)}
        >
          <Typography sx={{ fontSize: 12 }} color={'white'}>
            Ingen
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
