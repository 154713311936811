import { Box, Typography } from '@mui/material';
import React, { MouseEvent } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';

interface StatusCellProps {
  status: string | null | undefined;
  onClick?: (event: MouseEvent<HTMLTableCellElement>) => void;
  className?: string | undefined;
}

export const StatusCell: React.FC<StatusCellProps> = ({
  status,
  onClick,
  className,
}) => {
  const { currentTeam } = useGlobalContext();

  const statusData = currentTeam?.settings?.statuses?.find(
    (data) => data.name === status
  );

  return (
    <Box
      className={className}
      sx={{
        bgcolor: statusData?.color ?? '#818589',
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      onClick={onClick}
    >
      <Typography
        sx={{ fontSize: 12 }}
        color={statusData?.textColor ?? 'white'}
      >
        {statusData?.name ?? 'To Do'}
      </Typography>
    </Box>
  );
};
