import React from 'react';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import { Box, Typography } from '@mui/material';

export const Statz: React.FC = () => {
  return (
    <Widget width={4} height={'xShort'}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>xShort</Typography>
      </Box>
    </Widget>
  );
};
