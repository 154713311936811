/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { DonutLarge } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Doughnut } from 'components/Charts/Doughnut';
import { Widget } from 'components/layout/WidgetGrid/Widget';
import { GET_MY_TASKS } from 'graphql/queries/Projects/getMyTasks';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { Task } from 'types/task';

type StatusChartData = {
  label: string;
  value: number;
  color: string;
};

export const TaskStatistics: React.FC = () => {
  const [taskData, setTaskData] = useState<StatusChartData[]>([]);

  const { currentTeam, currentUser } = useGlobalContext();

  const { data } = useQuery(GET_MY_TASKS, {
    variables: { userId: currentUser?.id, teamId: currentTeam?.id },
  });

  const renderData = () => {
    let chartData: StatusChartData[] =
      currentTeam?.settings?.statuses?.map((status) => {
        return {
          label: status?.name,
          color: `${status?.color}85`,
          value: 0,
        } as StatusChartData;
      }) ?? [];
    chartData?.push({ label: 'To Do', color: '#81858980', value: 0 });
    data?.getMyTasks?.forEach((task: Task) => {
      let index = chartData?.findIndex((x) => x?.label === task?.status);
      if (index > -1) {
        chartData[index].value += 1;
      } else {
        chartData[chartData?.length - 1].value += 1;
      }
    });
    setTaskData(chartData);
  };

  useEffect(() => {
    renderData();
  }, [data]);
  return (
    <Widget
      width={4}
      height='medium'
      title='Oppgavestatistikk'
      subtitle='Status på dine oppgaver'
      icon={<DonutLarge />}
    >
      {data?.getMyTasks?.length > 0 ? (
        <Doughnut
          values={taskData?.map((data) => data?.value)}
          labels={taskData?.map((data) => data?.label)}
          colors={taskData?.map((data) => data?.color)}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='body2' sx={{ color: 'GrayText' }}>
            Foreløpig ingen oppgaver
          </Typography>
        </Box>
      )}
    </Widget>
  );
};
