import { gql } from '@apollo/client';

export const EDIT_TASK = gql`
  mutation EditTask($projectId: ID, $taskId: ID, $data: TaskInput) {
    editTask(projectId: $projectId, taskId: $taskId, data: $data) {
      _id
      name
      description
      status
      priority
      deadline
      owner
      hours
      hoursEstimated
      timestamp
    }
  }
`;
