import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from '../../components/Alert/Alert';
import { AlertProps } from '../../types/alert';
import { createGenericContext } from './createGenericContext';

interface NotificationContextType {
  alerts: Map<string, React.ReactNode>;
  addAlert: (id: string, props: AlertProps) => void;
  hideAlert: (id: string) => void;
}

export const [useNotificationContext, BareNotificationContextProvider] =
  createGenericContext<NotificationContextType>();

export const NotificationContextProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Map<string, React.ReactNode>>(
    new Map<string, React.ReactNode>()
  );

  useEffect(() => {
    // console.log(alerts);
  }, [alerts]);

  const hideAlert = useCallback(
    (id: string) => {
      let next = new Map<string, React.ReactNode>(alerts);
      next.delete(id);
      setAlerts(next);
    },
    [alerts]
  );

  const addAlert = useCallback(
    (id: string, props: AlertProps) => {
      let next = new Map<string, React.ReactNode>(alerts);
      next.set(id, <Alert {...props} />);
      setAlerts(next);
    },
    [alerts]
  );

  const value = useMemo(
    () => ({
      alerts: alerts,
      addAlert,
      hideAlert,
    }),
    [addAlert, alerts, hideAlert]
  );

  return (
    <BareNotificationContextProvider value={value}>
      {children}
    </BareNotificationContextProvider>
  );
};
