export const routes = {
  home: '/',
  login: '/login',
  signup: '/signup',
  dashboard: '/dashboard',
  admin: '/admin',
  profile: '/dashboard/profile',
  projects: '/dashboard/projects',
  createProject: '/dashboard/projects/createproject',
  settings: '/dashboard/settings',
  resetPassword: '/tilbakestill',
};
