import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EDIT_MONDAY_SETTINGS } from 'graphql/mutations/Monday/editMondaySettings';
import { GET_TODOS } from 'graphql/queries/Monday/getTodos';
import { GET_BOARDS_AND_WORKSPACES } from 'graphql/queries/Monday/getWorkspaces';
import { GET_PROJECT } from 'graphql/queries/Projects/getProject';
import React, { useEffect, useState } from 'react';
import { useDashboardContext } from 'state/context/DashboardContext';
import { useGlobalContext } from 'state/context/GlobalContext';

// 1. If no default workspace, select workspace
// 2. When workspace is selected, present default board or first from list
// 3. Switch between boards and groups with pickers
export const Todos: React.FC = () => {
  const classes = useTodoStyles();
  const { currentUser, currentSettings } = useGlobalContext();
  const { currentProject } = useDashboardContext();

  const [currentWorkspace, setCurrentWorkspace] = useState<any>();
  const [workspaceInput, setWorkspaceInput] = useState<number | string>(
    currentSettings?.dashboard?.monday?.defaultWorkspace ?? 'main'
  );
  const [board, setBoard] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: {
      id: currentProject,
    },
  });

  const { data: workspaceData } = useQuery(GET_BOARDS_AND_WORKSPACES, {
    variables: {
      userId: currentUser?.id,
    },
  });

  const { data: todoData, loading: todoLoading } = useQuery(GET_TODOS, {
    variables: {
      userId: currentUser?.id,
      boardId: projectData?.getProject?.mondayBoard ?? board,
    },
    skip: !currentUser?.id || (!board && !projectData?.getProject?.mondayBoard),
  });

  const [editSettings] = useMutation(EDIT_MONDAY_SETTINGS);

  const handleNextClicked = () => {
    editSettings({
      variables: {
        userId: currentUser?.id,
        settings: {
          ...currentSettings?.dashboard?.monday,
          defaultWorkspace: workspaceInput ?? null,
        },
      },
    });
  };

  useEffect(() => {
    if (workspaceData?.getBoardsAndWorkspaces) {
      setCurrentWorkspace(
        workspaceData.getBoardsAndWorkspaces.find(
          (x: any) =>
            x?.id === currentSettings?.dashboard?.monday?.defaultWorkspace
        )
      );
    }
  }, [workspaceData, currentSettings?.dashboard?.monday?.defaultWorkspace]);

  const getInitials = (name: string) => {
    let initials = name
      ?.split(' ')
      .map((x) => x.charAt(0))
      .join('');
    return initials !== '' ? initials : '-';
  };

  const getStatus = (label: string) => {
    let data = todoData?.getTodos?.statuses?.find(
      (status: any) => status?.label === label
    );
    return data;
  };

  const getDeadline = (deadline: string) => {
    let parsed = deadline?.split('-');
    return deadline ? `${parsed[2]}.${parsed[1]}` : '-';
  };

  useEffect(() => {
    setPage(0);
  }, [board, projectData?.getProject?.mondayBoard]);

  return (
    <Box className={classes.root}>
      {!currentSettings?.dashboard?.monday?.defaultWorkspace ? (
        <Box className={classes.selectWorkspace}>
          <FormControl className={classes.workspacePicker}>
            <InputLabel>Velg workspace</InputLabel>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              label='Velg workspace'
              value={workspaceInput}
              onChange={(e) => setWorkspaceInput(e.target.value)}
            >
              <MenuItem value={'main'}>Main</MenuItem>
              {workspaceData?.getBoardsAndWorkspaces?.map((ws: any) => {
                return <MenuItem value={ws?.id}>{ws?.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <Button variant='contained' onClick={handleNextClicked}>
            Fortsett
          </Button>
        </Box>
      ) : (
        <Grid sx={{ height: '100%' }} container spacing={2}>
          {currentProject === '*' && (
            <Grid sx={{ height: '15%' }} item xs={12} md={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Velg board</InputLabel>
                <Select
                  labelId='demo-select-small'
                  id='demo-select-small'
                  label='Velg board'
                  value={board}
                  onChange={(e) => {
                    setBoard(e.target.value as number);
                  }}
                >
                  {currentWorkspace?.boards?.map((board: any) => {
                    return <MenuItem value={board?.id}>{board?.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid sx={{ height: '15%' }} item xs={12} md={6}>
            <FormControl fullWidth size='small'>
              <InputLabel>Velg gruppe</InputLabel>
              <Select
                label='Velg gruppe'
                //value={board}
                //onChange={(e) => setWorkspace(e.target.value)}
              ></Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ height: '70%' }}>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                sx={{ fontSize: 10, overflowY: 'auto' }}
                className={classes.table}
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Oppgave</TableCell>
                    <TableCell align='right'>Person</TableCell>
                    <TableCell align='right'>Status</TableCell>
                    <TableCell align='right'>Deadline</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {todoLoading ? (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            height: 200,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    todoData?.getTodos?.items
                      ?.slice(page * 5, page * 5 + 5)
                      .map((item: any) => {
                        return (
                          <TableRow key={item?.name}>
                            <TableCell>{item?.name}</TableCell>
                            <Tooltip title={item?.person} placement='top'>
                              <TableCell align='right'>
                                {getInitials(item?.person)}
                              </TableCell>
                            </Tooltip>
                            <Tooltip title={item?.status ?? 'To Do'}>
                              <TableCell sx={{ py: 0 }} align='right'>
                                <Box className={classes.statusContainer}>
                                  <Box
                                    className={classes.statusCircle}
                                    sx={{
                                      backgroundColor: getStatus(item?.status)
                                        ?.color,
                                      borderColor:
                                        getStatus(item?.status)?.border ??
                                        'lightgrey',
                                    }}
                                  ></Box>
                                </Box>
                              </TableCell>
                            </Tooltip>
                            <Tooltip
                              title={item?.deadline ?? 'Ingen tidslinje'}
                            >
                              <TableCell align='right'>
                                {getDeadline(item?.deadline)}
                              </TableCell>
                            </Tooltip>
                          </TableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid sx={{ height: '10%' }} item xs={12}>
            <Pagination
              className={classes.pagination}
              count={Math.ceil((todoData?.getTodos?.items?.length ?? 0) / 5)}
              page={page + 1}
              onChange={(e, val) => {
                setPage(val - 1);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const useTodoStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,

    maxHeight: '90%',
  },
  selectWorkspace: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  workspacePicker: {
    width: 200,
    marginBottom: 10,
  },
  tableContainer: {
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#e6e6e6',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: 15,
    },
  },
  table: {
    '& .MuiTableCell-head': {
      fontSize: 12,
    },
    '& .MuiTableCell-body': {
      fontSize: 12,
    },
  },
  statusCircle: {
    borderRadius: '50%',
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.1,
    //borderColor: 'lightgrey',
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));
