import { gql } from '@apollo/client';

export const SET_TASK_HOURS_ESTIMATED = gql`
  mutation SetTaskHoursEstimated(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $hours: Float
  ) {
    setTaskHoursEstimated(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      hours: $hours
    ) {
      name
    }
  }
`;
