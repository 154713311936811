import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { client } from '../graphql/config';
import { GlobalContextProvider } from '../state/context/GlobalContext';
import { NotificationContextProvider } from '../state/context/NoticifationContext';
import { ThemeProvider } from './ThemeProvider';
import { BrowserRouter } from 'react-router-dom';

export const AppProvider: React.FC = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <NotificationContextProvider>
        <GlobalContextProvider>
          <ThemeProvider>
            <BrowserRouter>{children}</BrowserRouter>
          </ThemeProvider>
        </GlobalContextProvider>
      </NotificationContextProvider>
    </ApolloProvider>
  );
};
