import { gql } from '@apollo/client';

export const ADD_NOTIFICATION = gql`
  mutation AddNotification($data: NotificationInput) {
    addNotification(data: $data) {
      id
      message
      type
      timestamp
      sender
      readBy
      subject
    }
  }
`;
