import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Board, { addCard, moveCard, changeCard } from '@asseinfo/react-kanban';
import { Add, MoreHoriz, Schedule } from '@mui/icons-material';
import { useProjectContext } from 'state/context/ProjectContext';
import { Task } from 'types/task';
import _ from 'lodash';
import { useGlobalContext } from 'state/context/GlobalContext';
import { renderDateString } from 'utils/DateHandler';
import { useMutation } from '@apollo/client';
import { SET_TASK_STATUS } from 'graphql/mutations/Projects/setTaskStatus';
import { AddTaskModal } from '../AddTaskModal';
import { Status } from 'types/team';
import { EditTaskModal } from '../EditTaskModal';
import { displayName } from 'utils/Stringparser';
import { ADD_TO_LOG } from 'graphql/mutations/ActivityLog/addToLog';

interface KanbanProps {}

const renderData = (tasks: Task[], statuses: Status[] | null | undefined) => {
  let columns = _.groupBy(tasks, (task: Task) => task.status);
  return {
    columns: [
      {
        id: 'To Do',
        title: 'To Do',
        color: '#818589',
        textColor: 'white',
        cards:
          columns['null']?.map((task) => ({ ...task, id: task?._id })) ?? [],
      },
      ...(statuses?.map((status) => ({
        id: status?.name,
        title: status?.name,
        color: status?.color,
        textColor: status?.textColor,
        cards:
          columns[status.name]?.map((task) => ({ ...task, id: task._id })) ??
          [],
      })) ?? []),
    ],
  };
};

export const Kanban: React.FC<KanbanProps> = () => {
  const { currentProject, projectMembers } = useProjectContext();
  const { currentTeam, currentUser } = useGlobalContext();

  const [addTask, setAddTask] = useState<string | null>(null);
  const [board, setBoard] = useState<any>(
    renderData(currentProject?.tasks ?? [], currentTeam?.settings?.statuses)
  );
  const [editTask, setEditTask] = useState<Task | null>(null);

  const [changeStatus] = useMutation(SET_TASK_STATUS);
  const [addToLog] = useMutation(ADD_TO_LOG);

  const handleMoveTask = (
    task: any,
    source: { fromPosition: number; fromColumnId: string },
    destination: { toPosition: number; toColumnId: string }
  ) => {
    const newBoard = moveCard(board, source, destination);
    setBoard(newBoard);
    changeStatus({
      variables: {
        projectId: currentProject?.id,
        taskId: task?.id,
        status:
          destination?.toColumnId === 'To Do' ? null : destination?.toColumnId,
      },
    }).then(() => {
      addToLog({
        variables: {
          data: {
            type: `task-status:${destination?.toColumnId ?? null}`,
            madeBy: currentUser?.id,
            teamId: currentTeam?.id,
            projectId: currentProject?.id,
            description: `Endret status på oppgave til "${
              destination?.toColumnId ?? null
            }"`,
          },
        },
      });
    });
  };

  const getPriorityData = (priority: string | null | undefined) =>
    currentTeam?.settings?.priorityLabels?.find(
      (prio) => prio?.name === priority
    );

  const getUserData = (userId: string | null | undefined) =>
    projectMembers?.find((member) => userId === member?.id);
  /* const handeSetFormValue = ({
    currentTarget,
  }: {
    currentTarget: HTMLInputElement;
  }) => setFormValue(currentTarget.value); */
  return (
    <Box sx={{ maxHeight: '100%' }}>
      <AddTaskModal
        open={addTask ? true : false}
        onClose={() => setAddTask(null)}
        onSave={(task) =>
          setBoard(
            addCard(
              board,
              { id: task?.status ?? 'To Do' },
              { ...task, id: task?._id },
              { on: 'top' }
            )
          )
        }
        status={addTask ?? 'To Do'}
      />
      {editTask && (
        <EditTaskModal
          open={editTask ? true : false}
          onClose={() => setEditTask(null)}
          onSave={(task) => setBoard(changeCard(board, task?._id, task))}
          data={editTask}
        />
      )}
      <Box
        position='relative'
        sx={{
          '& .react-kanban-column': {
            width: 250,
            margin: 2,
            padding: 2,
            borderRadius: 5,
            height: '100%',
          },
          '& .react-kanban-board': {
            height: '70vh',
            overflowY: 'auto !important',
          },
        }}
      >
        <Board
          children={board}
          allowAddColumn
          renderColumnHeader={({ id, title, color, textColor }: any) => (
            <>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={3}
              >
                <Box
                  sx={{
                    boxShadow: 3,
                    backgroundColor: color,
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                  }}
                ></Box>
                <Typography variant='h6'>{title}</Typography>
                <IconButton onClick={() => setAddTask(id)} color='primary'>
                  <Add />
                </IconButton>
              </Box>
            </>
          )}
          renderCard={(task: any, { dragging }: any) => (
            <Box
              key={task?.id}
              sx={{
                display: 'block',
                width: 218,
                fontSize: 12,
                bgColor: 'white',
                borderRadius: 10,
                mt: 1,
              }}
            >
              <Card>
                <CardContent sx={{ py: 0.5, px: 1 }}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexDirection='row-reverse'
                    alignItems='center'
                    mb={1}
                  >
                    <IconButton
                      size='small'
                      onClick={() => {
                        setEditTask(task);
                      }}
                    >
                      <MoreHoriz />
                    </IconButton>
                    {task?.priority && (
                      <Chip
                        label={task?.priority}
                        size='small'
                        sx={{
                          color: getPriorityData(task?.priority)?.textColor,
                          backgroundColor: getPriorityData(task?.priority)
                            ?.color,
                          fontSize: 10,
                        }}
                      />
                    )}
                  </Box>
                  <Typography variant='h6' sx={{ fontSize: 12 }}>
                    {task?.name}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ fontSize: 10, color: 'GrayText' }}
                  >
                    {task?.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                  }}
                >
                  {task?.owner && (
                    <Tooltip title={displayName(getUserData(task?.owner))}>
                      <Avatar
                        src={getUserData(task?.owner)?.avatar}
                        sx={{
                          width: 24,
                          height: 24,
                          alignSelf: 'flex-end',
                        }}
                      />
                    </Tooltip>
                  )}
                  {task?.deadline && (
                    <Box
                      display='flex'
                      alignItems={'center'}
                      justifySelf='flex-end'
                    >
                      <Schedule fontSize='small' />
                      <Typography sx={{ fontSize: 10, ml: 1 }}>
                        {renderDateString(task?.deadline)}
                      </Typography>
                    </Box>
                  )}
                </CardActions>
              </Card>
            </Box>
          )}
          onCardDragEnd={(card: any, source: any, destination: any) => {
            handleMoveTask(card, source, destination);
          }}
        />
      </Box>
    </Box>
  );
};
