import { gql } from '@apollo/client';

export const GET_PROJECT_HOURS = gql`
  query GetProjectHours($projectId: ID) {
    getProjectHours(projectId: $projectId) {
      current
      estimated
    }
  }
`;
