import { gql } from '@apollo/client';

export const SET_TASK_PRIORITY = gql`
  mutation SetTaskPriority(
    $projectId: ID
    $parentId: ID
    $taskId: ID
    $priority: String
  ) {
    setTaskPriority(
      projectId: $projectId
      parentId: $parentId
      taskId: $taskId
      priority: $priority
    ) {
      name
    }
  }
`;
