import { gql } from '@apollo/client';

export const SET_PROJECT_NAME = gql`
  mutation SetProjectName($projectId: ID, $name: String) {
    setProjectName(projectId: $projectId, name: $name) {
      id
      name
      mondayWorkspace
      mondayBoard
      teamId
      powerofficeProject
      description
      startDate
      endDate
      members
      createdByName
      createdById
      createdAt
      projectLeaders
      tasks {
        _id
        name
        description
        status
        priority
        deadline
        owner
        hours
        hoursEstimated
      }
    }
  }
`;
