import { gql } from '@apollo/client';

export const GET_BOARDS_AND_WORKSPACES = gql`
  query GetBoardsAndWorkspaces($userId: ID) {
    getBoardsAndWorkspaces(userId: $userId) {
      id
      name
      boards {
        id
        name
      }
    }
  }
`;
